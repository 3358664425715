import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { getPlanningCrAction } from '../../actions/planningActions';
import { SET_ACTIVE_CR, SET_ACTIVE_CR_LIST } from '../../actions/types';
import styles from './CrList.module.scss';

function CrItem({ day }) {
  const dispatch = useDispatch();
  const {
    cr,
  } = useSelector((state) => state.planning);
  function displayCRPanel() {
    const crDay = {
      date: day.date,
      period: day.period,
      type: day.type,
      cr: {
        drug: day.cr.drug?._id,
        title: day.cr.title,
        files: day.cr.files,
        center: day.cr.center?._id,
        project: day.cr.project?._id,
        therapeuticArea: day.cr.therapeuticArea?._id,
        content: day.cr.content,
      },
    };
    dispatch({
      type: SET_ACTIVE_CR,
      payload: {
        user: day.user,
        day: crDay,
      },
    });
  }
  const formatedDate = day?.date && new Intl.DateTimeFormat('fr', { dateStyle: 'short' }).format(new Date(day?.date));
  const isActive = cr?.day?.date === day.date
  && cr?.user === day.user && cr?.day?.period === day.period;
  return (
    <button
      type="button"
      onClick={() => displayCRPanel()}
      className={`${styles.cr} ${isActive ? styles.active : ''}`}
    >
      <h4>
        {day.cr?.title}
        {' - '}
        {day.cr?.drug?.name}
      </h4>
      <div className={styles.infos}>
        <p>{day?.cr?.center?.name}</p>
        <p>{formatedDate}</p>
      </div>
    </button>
  );
}

export default function CrList() {
  const dispatch = useDispatch();
  const {
    isCrList, planning, crList,
  } = useSelector((state) => state.planning);
  const [search, setSearch] = useState();

  function close() {
    dispatch({
      type: SET_ACTIVE_CR_LIST,
      payload: false,
    });
    dispatch({
      type: SET_ACTIVE_CR,
      payload: null,
    });
  }
  useEffect(() => {
    if (planning) {
      getPlanningCrAction(dispatch);
    }
  }, [planning]);

  const filtered = crList.filter((d) => {
    if (!search) {
      return d;
    }
    if (d?.cr?.center?.name?.toLowerCase().includes(search.toLowerCase())) {
      return d;
    }
    if (d?.cr?.drug?.name?.toLowerCase().includes(search.toLowerCase())) {
      return d;
    }
    if (d?.cr?.title?.toLowerCase().includes(search.toLowerCase())) {
      return d;
    }
    if (d?.cr?.content?.toLowerCase().includes(search.toLowerCase())) {
      return d;
    }
    return null;
  });

  return (
    <div className={`${styles.container} ${isCrList ? styles.active : ''}`}>
      <h1>Comptes-rendus</h1>
      <button
        type="button"
        onClick={() => close()}
        className={styles.close}
      >
        <IoClose />
      </button>
      <div className={styles.search}>
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Rechercher..."
        />
      </div>
      <div className={styles.list}>
        {filtered.map((day) => <CrItem key={day._id} day={day} />)}
      </div>
    </div>
  );
}
