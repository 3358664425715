import React, { useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import fieldIcon from '../../assets/images/icons/field.svg';
import sickIcon from '../../assets/images/icons/sick.svg';
import tripsIcon from '../../assets/images/icons/trips.svg';
import congressIcon from '../../assets/images/icons/congress.svg';
import trainingIcon from '../../assets/images/icons/training.svg';
import outdoorTrainingIcon from '../../assets/images/icons/outdoor_training.svg';
import officeIcon from '../../assets/images/icons/office.svg';
// import free from '../../assets/images/icons/free.svg';

import congress from '../../assets/images/icons/congress_white.svg';
import trips from '../../assets/images/icons/trips_white.svg';
import training from '../../assets/images/icons/training_white.svg';
import outdoorTraining from '../../assets/images/icons/outdoor_training_white.svg';
import field from '../../assets/images/icons/field_white.svg';
import office from '../../assets/images/icons/office_white.svg';
import sick from '../../assets/images/icons/sick_white.svg';
import off from '../../assets/images/icons/off.svg';

import styles from './PlanningRow.module.scss';
import Modal from '../ModalComponents/Modal';
import useWindowSize from '../../hooks/useWindowSize';
import { putPlanningAction } from '../../actions/planningActions';
import { SET_ACTIVE_CR } from '../../actions/types';

const othersOptions = [
  {
    value: 'congress',
    label: 'Congrés',
    color: '#5B2195',
    icon: congressIcon,
    disabled: true,
  },
  {
    value: 'training',
    label: 'Formation',
    color: '#D06666',
    icon: trainingIcon,
    disabled: true,
  },
];

const options = [
  {
    value: 'trips',
    label: 'Déplacement',
    color: '#D87BC3',
    icon: tripsIcon,
  },
  {
    value: 'sick',
    label: 'Maladie',
    color: '#DFA67E',
    icon: sickIcon,
  },
  {
    value: 'field',
    label: 'Terrain',
    color: '#08A592',
    icon: fieldIcon,
  },
  {
    value: 'outdoor_training',
    label: 'Formation extérieure',
    color: '#D06666',
    icon: outdoorTrainingIcon,
  },
  {
    value: 'off',
    label: 'Off',
    color: '#EEEEEE',
    icon: off,
  },
  {
    value: null,
    label: 'Bureau',
    color: '#EEEEEE',
    icon: officeIcon,
  },
];

export const planningOptions = [...othersOptions, ...options];

function Step({
  day, isUser = false, isMobile = false, isWeekend, isMonth,
}) {
  const isAll = day.day.find((d) => d?.period === 'all');
  const isCr = !isMonth && day.day.find((d) => d?.cr);
  const dayTimes = day.day.filter((d) => d?.period !== 'all');
  if (dayTimes.length > 0) {
    return (
      <div className={`
        ${styles.step} 
        ${styles.times}
        ${!isWeekend && (day.date.getDay() > 0 && day.date.getDay() < 6) ? styles['is-weekend'] : '' }
        ${!isWeekend && (day.date.getDay() === 0 || day.date.getDay() > 5) ? styles['d-none'] : '' }
        ${isUser ? styles['is-user'] : '' } ${isMobile ? styles['is-mobile'] : '' }
        ${isMonth && styles.isMonth}
      `}
      >
        {dayTimes.length === 1 && dayTimes.find((d) => d.period === 'pm')
          && (
            <div className={`${styles.time}`}>
              <img src={office} alt="office-icon" />
            </div>
          )}
        {dayTimes.map((d) => (
          <div key={d.key} className={`${styles.time} ${d.type ? styles[d.type] : '' }`}>
            {d.type === 'off' && <img src={off} alt="off-icon" />}
            {d.type === 'sick' && <img src={sick} alt="sick-icon" />}
            {d.type === 'training' && <img src={training} alt="training-icon" />}
            {d.type === 'field' && <img src={field} alt="field-icon" />}
            {d.type === 'trips' && <img src={trips} alt="trips-icon" />}
            {d.type === 'congress' && <img src={congress} alt="congress-icon" />}
            {d.type === 'outdoor_training' && <img src={outdoorTraining} alt="outdoor_training-icon" />}
            {!d.type && <img src={office} alt="office-icon" />}
            {isCr && d.type === 'field' && <div className={styles.cr} />}
          </div>
        ))}
        {dayTimes.length === 1 && dayTimes.find((d) => d.period === 'am')
          && (
          <div className={`${styles.time}`}>
            <img src={office} alt="office-icon" />
          </div>
          )}
      </div>
    );
  }

  return (
    <div className={`${styles.step} ${isUser ? styles['is-user'] : '' } ${!isWeekend && (day.date.getDay() > 0 && day.date.getDay() < 6) ? styles['is-weekend'] : '' } ${!isWeekend && (day.date.getDay() === 0 || day.date.getDay() > 5) ? styles['d-none'] : '' } ${isMobile ? styles['is-mobile'] : '' } ${styles.all} ${isAll.type ? styles[isAll.type] : '' }`}>
      {isAll.type === 'off' && <img src={off} alt="off-icon" />}
      {isAll.type === 'sick' && <img src={sick} alt="sick-icon" />}
      {isAll.type === 'training' && <img src={training} alt="training-icon" />}
      {isAll.type === 'field' && <img src={field} alt="field-icon" />}
      {isAll.type === 'trips' && <img src={trips} alt="trips-icon" />}
      {isAll.type === 'congress' && <img src={congress} alt="congress-icon" />}
      {isAll.type === 'outdoor_training' && <img src={outdoorTraining} alt="outdoor_training-icon" />}
      {!isAll.type && <img src={office} alt="office-icon" />}
      {isCr && <div className={styles.cr} />}
    </div>
  );
}

function UserStep({
  day, activeDay, setActiveDay, isMobile, user, isWeekend, isDayLabel = true, isMonth,
}) {
  const stepRef = useRef();
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const daySort = new Intl.DateTimeFormat('fr', { weekday: 'short' }).format(day.date);
  const dayLong = new Intl.DateTimeFormat('fr', { weekday: 'long', day: '2-digit', month: 'long' }).format(day.date);
  const isActive = day?.date && activeDay === day?.date;
  const stepRect = stepRef?.current?.getBoundingClientRect();
  const left = stepRect?.x ? stepRect.x + stepRect.width + 5 : 0;
  const leftHidden = stepRect?.x ? stepRect.x - 222 : 0;
  const isHidden = width - left < 230;
  const isMobileOptions = width < 500;
  const pos = {
    x: isHidden ? leftHidden : left,
    y: stepRect?.y && stepRect.y + 44,
  };
  if (isMobileOptions && leftHidden) {
    pos.x = 20;
    pos.y = stepRect.y + 130;
  }

  const onlySick = day.day.find((d) => d?.type === 'training' || d.type === 'congress');
  const fieldDay = day.day.find((d) => d?.type === 'field');
  function handleChangeDay(data) {
    putPlanningAction(dispatch, user._id, { day: { ...data, date: day.date.toISOString() } });
  }

  function displayCRPanel() {
    dispatch({
      type: SET_ACTIVE_CR,
      payload: {
        user: user._id,
        day: {
          ...fieldDay,
          date: day.date.toISOString(),
        },
      },
    });
  }

  useEffect(() => {
    setActiveDay(null);
  }, [isWeekend]);

  return (
    <button
      type="button"
      ref={stepRef}
      className={`
        ${styles.auth}
        ${isMonth && styles.isMonth}
        ${!isWeekend && (day.date.getDay() > 0 && day.date.getDay() < 6) ? styles['is-weekend'] : '' }
        ${!isWeekend && (day.date.getDay() === 0 || day.date.getDay() > 5) ? styles['d-none'] : '' }
        ${isMobile ? styles['is-mobile'] : '' } ${isActive ? styles.active : ''}
      `}
      onClick={() => setActiveDay(
        isActive ? null : day?.date,
      )}
    >
      {isDayLabel
      && (
      <div className={styles.day}>
        <p>{daySort}</p>
        <p>{day.date.getDate()}</p>
      </div>
      )}
      <Step day={day} isUser isWeekend={isWeekend} isMonth={isMonth} onlySick={onlySick} />
      <Modal
        toggle={() => setActiveDay()}
        open={isActive}
      >
        <div className={styles.modal}>
          <div className={styles.header}>
            <h1>{dayLong}</h1>
            <h4>
              {user.profile?.firstName}
              {' '}
              {user.profile?.lastName}
            </h4>
            {fieldDay
            && (
            <button
              type="button"
              className={styles.cr}
              onClick={() => displayCRPanel()}
            >
              {!fieldDay.cr ? '+ Ajouter un compte-rendu' : 'Voir le compte-rendu'}
            </button>
            )}

          </div>
          <div
            className={`${styles.options} ${onlySick ? styles.sick : ''} ${fieldDay ? styles.field : ''} ${isActive ? styles.active : ''}`}
          >
            {planningOptions.filter((opt) => {
              if (!onlySick) {
                return opt;
              }
              if (opt.value === 'sick') {
                return opt;
              }
              return null;
            }).map((opt) => (
              <div
                key={day.date.toISOString() + opt.label}
                className={`${styles.option} ${opt.disabled ? styles.disabled : ''}`}
              >
                <div className={styles.label}>
                  <p>{opt.label}</p>
                  <div className={styles.icon}>
                    <img src={opt.icon} alt={opt.value} />
                  </div>
                </div>
                <div className={styles.times}>
                  <button
                    type="button"
                    onClick={() => handleChangeDay({ type: opt.value, period: 'all' })}
                  >
                    Journée
                  </button>
                  <button
                    type="button"
                    onClick={() => handleChangeDay({ type: opt.value, period: 'am' })}
                  >
                    Matin
                  </button>
                  <button
                    type="button"
                    onClick={() => handleChangeDay({ type: opt.value, period: 'pm' })}
                  >
                    Aprés-midi
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </button>
  );
}

export default function PlanningRow({
  user, days, isAuth, isAdmin, isMobile, isWeekend, isMonth,
}) {
  const { firstName, lastName } = user.profile;
  const [activeDay, setActiveDay] = useState();
  return (
    <div className={`${styles.container} ${isAuth ? styles.active : ''}`}>
      <div className={styles.user}>
        {isAuth
          ? (
            <p>
              Mon planning
            </p>
          )
          : (
            <p>
              {firstName}
              {' '}
              {lastName}
            </p>
          )}
      </div>
      <div className={styles.days}>
        {!isAuth && !isAdmin && days?.map((d) => (
          <Step
            key={d.key}
            day={d}
            isMobile={isMobile}
            isWeekend={isWeekend}
            isMonth={isMonth}
          />
        ))}

        {!isAuth && isAdmin && days?.map((d) => (
          <UserStep
            key={d.key}
            day={d}
            activeDay={activeDay}
            isMonth={isMonth}
            user={user}
            isDayLabel={false}
            isMobile={isMobile}
            isWeekend={isWeekend}
            setActiveDay={setActiveDay}
          />
        ))}
        {isAuth && days?.map((d) => (
          <UserStep
            key={d.key}
            day={d}
            activeDay={activeDay}
            user={user}
            isMonth={isMonth}
            isMobile={isMobile}
            isWeekend={isWeekend}
            setActiveDay={setActiveDay}
          />
        ))}

      </div>
    </div>
  );
}
