import { getData, putData } from './index';
import {
  ERROR_PROJECTS, GET_PROJECT, GET_PROJECTS, PROJECT_IS_LOADING, PUT_PROJECT, SET_TOAST,
} from './types';

export async function getProjectsAction(dispatch) {
  const url = '/project';
  let projects;
  dispatch({
    type: PROJECT_IS_LOADING,
    payload: true,
  });
  await getData(ERROR_PROJECTS, url, dispatch, true).then((response) => {
    projects = response.projects;
  });
  dispatch({
    type: GET_PROJECTS,
    payload: projects,
  });
}

export async function putProjectAction(dispatch, id, obj) {
  const url = `/project/${id}`;
  dispatch({
    type: PROJECT_IS_LOADING,
    payload: true,
  });
  let project;
  await putData(PUT_PROJECT, ERROR_PROJECTS, url, dispatch, obj, true).then((response) => {
    project = response.project;
  });
  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'La tâche à été modifée',
    },
  });
  dispatch({
    type: PUT_PROJECT,
    payload: project,
  });
}

export async function getProjectAction(dispatch, id) {
  const url = `/project/${id}`;
  let project;
  dispatch({
    type: PROJECT_IS_LOADING,
    payload: true,
  });
  await getData(ERROR_PROJECTS, url, dispatch, true).then((response) => {
    project = { ...response.project };
  });
  dispatch({
    type: GET_PROJECT,
    payload: project,
  });
}
