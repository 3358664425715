import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { SET_TOAST } from '../../actions/types';
import './toaster.scss';

export default function Toaster({ toast }) {
  const portalRoot = document.getElementById('portal-root');
  const dispatch = useDispatch();
  useEffect(() => {
    if (toast && toast.message) {
      setTimeout(() => {
        dispatch({
          type: SET_TOAST,
          payload: null,
        });
      }, 4000);
    }
  }, [toast, dispatch]);

  return createPortal(
    <div className={`toaster ${toast && toast.message ? 'isActive' : ''} ${toast && toast.type === 'success' ? 'success' : ''} ${toast && toast.type === 'error' ? 'error' : ''}`}>
      <p>{toast && toast.message ? toast.message : ''}</p>
    </div>,
    portalRoot,
  );
}
