import { getData, putData } from './index';
import { ERROR_NOTIFS, GET_NOTIFS, PUT_NOTIF } from './types';

export async function getNotificationsAction(dispatch) {
  const url = '/notification/all';
  let notifs;
  await getData(ERROR_NOTIFS, url, dispatch, true).then((response) => {
    notifs = response.notifications;
  });
  dispatch({
    type: GET_NOTIFS,
    payload: notifs,
  });
}

export async function putNotificationAction(dispatch, id, obj) {
  const url = `/notification/${id}`;
  let notif;
  await putData(PUT_NOTIF, ERROR_NOTIFS, url, dispatch, obj, true).then((response) => {
    notif = response.notification;
  });
  dispatch({
    type: PUT_NOTIF,
    payload: notif,
  });
}
