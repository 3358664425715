import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillStar } from 'react-icons/ai';

import styles from './ToolCard.module.scss';
import { putFavoritesAction } from '../../actions/userActions';

function ToolCard({ tool }) {
  const dispatch = useDispatch();

  const { userAuth } = useSelector((state) => state.auth);

  const isFavorite = userAuth?.favoriteTools?.find((t) => t === tool._id);

  function handleChangeFavorite(e) {
    e.preventDefault();
    let favoriteTools = userAuth?.favoriteTools ? [...userAuth.favoriteTools] : [];
    if (isFavorite) {
      favoriteTools = favoriteTools.filter((f) => f !== isFavorite);
    } else {
      favoriteTools.push(tool._id);
    }
    if (userAuth?._id) {
      putFavoritesAction(dispatch, { favoriteTools });
    }
  }

  function redirectToTool(e) {
    e.preventDefault();
    if (!tool.url) return null;
    return window.open(tool.url, '_blank');
  }

  function getColor() {
    if (tool.color) return tool.color;
    if (tool?.icon?.path) return 'transparent';
    return '#D0D0D2';
  }

  return (
    <div className={styles.container}>
      <button
        type="button"
        onClick={(e) => redirectToTool(e)}
        className={styles.link}
      >
        <div
          className={styles.logo}
          style={{ backgroundColor: getColor() }}
        >
          {tool?.icon?.path
            ? <img src={`${process.env.REACT_APP_API}/${tool?.icon?.path}`} alt="tool" />
            : (
              <p>
                {tool?.title && tool?.title[0]}
              </p>
            )}
        </div>
        <div className={styles.infos}>
          <h3>{tool?.title}</h3>
          {tool.description !== 'undefined'
          && (
          <p>
            {tool?.description?.length > 100 ? `${tool?.description.trim().substring(0, 100) }...` : tool?.description}
          </p>
          )}
        </div>
      </button>
      <div className={styles.star}>
        <button
          type="button"
          onClick={(e) => handleChangeFavorite(e)}
          className={isFavorite ? styles['is-favorite'] : ''}
        >
          <AiFillStar />
        </button>
      </div>
    </div>
  );
}

export default ToolCard;
