import {
  ERROR_NOTIFS,
  MESSAGE_NOTIFS,
  GET_NOTIFS,
  PUT_NOTIF,
} from '../actions/types';

const initialState = {
  error: null,
  message: null,
  notifications: [],
};

export default function notifictionReducer(state = initialState, action = {}) {
  const list = state.notifications.map((n) => ({ ...n }));
  const index = list.findIndex((n) => n._id === action.payload?._id);
  switch (action.type) {
    case PUT_NOTIF:
      if (index !== -1) {
        list[index] = { ...action.payload };
      }
      return { ...state, notifications: [...list] };
    case GET_NOTIFS:
      return { ...state, notifications: action.payload };
    case MESSAGE_NOTIFS:
      return { ...state, message: action.payload };
    case ERROR_NOTIFS:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
}
