import {
  ERROR_PLANNINGS,
  MESSAGE_PLANNINGS,
  GET_PLANNING,
  GET_PLANNING_LIST,
  PUT_PLANNING,
  SET_ACTIVE_CR,
  SET_ACTIVE_CR_LIST,
  GET_CR_LIST,
  // PUT_PLANNING,
} from '../actions/types';

const initialState = {
  error: null,
  message: null,
  planning: [],
  list: null,
  startDate: null,
  endDate: null,
  cr: null,
  isCrList: false,
  crList: [],
};

export default function planningReducer(state = initialState, action = {}) {
  const list = state?.planning?.map((n) => ({ ...n }));
  const index = list.findIndex((n) => n.user._id === action.payload?.user);
  const foundActiveCr = action?.payload?.days?.find(
    (d) => d.date === state.cr?.day?.date
    && d.period === state.cr?.day?.period
    && d.user === action.payload?.user,
  );

  switch (action.type) {
    case PUT_PLANNING:
      if (index !== -1) {
        list[index].days = action.payload.days;
      }
      return {
        ...state,
        planning: [...list],
        cr: foundActiveCr ? {
          day: foundActiveCr,
          user: action.payload?.user,
        } : null,
      };
    case GET_PLANNING:
      return {
        ...state,
        planning: action.payload.users,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    case GET_PLANNING_LIST:
      return { ...state, list: action.payload };
    case GET_CR_LIST:
      return { ...state, crList: action.payload };
    case SET_ACTIVE_CR_LIST:
      return { ...state, isCrList: action.payload };
    case SET_ACTIVE_CR:
      return { ...state, cr: action.payload };
    case MESSAGE_PLANNINGS:
      return { ...state, message: action.payload };
    case ERROR_PLANNINGS:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
}
