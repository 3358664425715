import {
  ERROR_AUTH,
  USER_AUTH,
  MESSAGE_AUTH,
  SET_TOAST,
} from '../actions/types';
// test

const initialState = {
  error: null,
  message: null,
  userAuth: null,
  toast: null,
};

export default function authReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_TOAST:
      return { ...state, toast: action.payload };
    case MESSAGE_AUTH:
      return { ...state, message: action.payload };
    case ERROR_AUTH:
      return { ...state, error: action.payload };
    case USER_AUTH:
      return { ...state, userAuth: { ...action.payload } };
    default:
      return { ...state };
  }
}
