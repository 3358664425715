/* eslint-disable default-param-last */
import {
  DELETE_FILE,
  ERROR_FILE,
  POST_FILE,
} from '../actions/types';

const initialState = {
  error: null,
  file: null,
  message: null,
};

export default function filesReducer(state = initialState, action) {
  switch (action.type) {
    case ERROR_FILE:
      return { ...state, error: action.payload };
    case POST_FILE:
      return { ...state, file: action.payload };
    case DELETE_FILE:
      return { ...state, file: null };
    default:
      return { ...state };
  }
}
