import {
  ERROR_TODOS,
  MESSAGE_TODOS,
  GET_TODOS,
  PUT_TODO,
  POST_TODO,
  DELETE_TODO,
} from '../actions/types';

const initialState = {
  error: null,
  message: null,
  todosList: [],
};

export default function todoReducer(state = initialState, action = {}) {
  const list = state.todosList.map((n) => ({ ...n }));
  const index = list.findIndex((n) => n._id === action.payload?._id);
  switch (action.type) {
    case PUT_TODO:
      if (index !== -1) {
        list[index] = { ...action.payload };
      }
      return { ...state, todosList: [...list] };
    case DELETE_TODO:
      return { ...state, todosList: list.filter((t) => t._id !== action.payload) };
    case POST_TODO:
      return { ...state, todosList: [action.payload, ...list] };
    case GET_TODOS:
      return { ...state, todosList: action.payload };
    case MESSAGE_TODOS:
      return { ...state, message: action.payload };
    case ERROR_TODOS:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
}
