import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getProjectAction, getProjectsAction } from '../../actions/projectsActions';
import { getPlanningListAction } from '../../actions/planningActions';
import { GET_PROJECT } from '../../actions/types';
import ProjectRow from '../../components/ProjectRow/ProjectRow';
import SortButton from '../../lib/SortButton';

import styles from './Projects.module.scss';
import ProjectPanel from '../../components/ProjectPanel/ProjectPanel';
import TasksPanel from '../../components/TasksPanel/TasksPanel';

export default function Projects() {
  const dispatch = useDispatch();
  const { projectsList, project } = useSelector((state) => state.projects);

  const initSortState = {
    name: true,
    duration: true,
    type: true,
    endDate: true,
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const [sortByReverse, setSortByReverse] = useState(initSortState);
  const [filteredList, setFilteredList] = useState([]);
  const [todoIsActive, setTodoIsActive] = useState(false);

  const projectId = searchParams.get('project');

  const getData = () => {
    getProjectsAction(dispatch);
    getPlanningListAction(dispatch);
  };

  function sortList(type, isReverse) {
    let list = filteredList;
    switch (type) {
      case 'name':
        list = list.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        break;
      case 'duration':
        list = list.sort((a, b) => {
          if (a?.duration < b?.duration) return -1;
          if (a?.duration > b?.duration) return 1;
          return 0;
        });
        break;
      case 'type':
        list = list.sort((a, b) => {
          if (a?.type < b?.type) return -1;
          if (a?.type > b?.type) return 1;
          return 0;
        });
        break;
      case 'endDate':
        list = list.sort((a, b) => {
          const aDate = a?.endDate ? new Date(a?.endDate) : new Date();
          const bDate = b?.endDate ? new Date(b?.endDate) : new Date();
          if (aDate < bDate) return -1;
          if (aDate > bDate) return 1;
          return 0;
        });
        break;
      default:
        break;
    }
    setFilteredList(isReverse ? list.reverse() : list);
    setSortByReverse({ ...initSortState, [type]: isReverse });
  }

  function close() {
    setSearchParams({});
    dispatch({
      type: GET_PROJECT,
      payload: null,
    });
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (projectId) {
      getProjectAction(dispatch, projectId);
    }
  }, [projectId]);

  useEffect(() => {
    setFilteredList(projectsList || []);
  }, [projectsList]);

  return (
    <div className={styles.container}>
      <main className={`${project?._id ? styles['is-project'] : ''}`}>
        <h2>PROJETS</h2>
        <button
          type="button"
          className={styles.todo}
          onClick={() => setTodoIsActive(true)}
        >
          MA TODO
        </button>
        <div className={styles.sort}>
          <div className={`${styles.row} ${styles.labels}`}>
            <SortButton
              name="Nom"
              handleClick={() => sortList('name', !sortByReverse.name)}
              state={sortByReverse.name}
              className={`${styles.col}`}
            />
            <SortButton
              name="Type"
              handleClick={() => sortList('type', !sortByReverse.type)}
              state={sortByReverse.type}
              className={`${styles.col}`}
            />
            <div className={`${styles.col} ${styles.desktop}`} />
            {/* <SortButton
              name="Date de livraison"
              handleClick={() => sortList('endDate', !sortByReverse.endDate)}
              state={sortByReverse.endDate}
              className={`${styles.col} ${styles.desktop}`}
            /> */}
            <div className={`${styles.edit} ${styles.desktop}`} />
          </div>
        </div>
        <div
          className={styles.list}
        >
          <div>
            {filteredList?.length > 0 && filteredList?.map((p) => (
              <ProjectRow
                key={p._id}
                project={p}
                setSearchParams={setSearchParams}
                isActive={project?._id === p._id}
                styles={styles}
              />
            ))}
          </div>
        </div>
      </main>
      <aside className={`${project?._id || todoIsActive ? styles.active : ''}`}>
        {project?._id
          ? (<ProjectPanel close={() => close()} />)
          : (<TasksPanel close={() => setTodoIsActive(false)} />)}
      </aside>
    </div>
  );
}
