import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IoClose } from 'react-icons/io5';
import { createPortal } from 'react-dom';
import { animated, useSpring } from 'react-spring';
import styles from './modal.module.scss';

function Modal({
  toggle,
  open = false,
  children,
  domNode = 'portal-root',
}) {
  const modalRoot = document.getElementById(domNode);
  const [toggleAnimation, setToggleAnimation] = useState(false);

  const spring = useSpring({
    opacity: toggleAnimation ? 1 : 0,
    onRest: (state) => {
      if (state.value.opacity === 0) toggle();
    },
  });

  useEffect(() => {
    if (!modalRoot) {
      console.error(`Can't find the dom element (#${domNode}) where this modal should be mount \nYou should add a div with id : "${domNode}" to public/index.html
     `);
    }
  }, [modalRoot, domNode]);

  useEffect(() => {
    if (open) {
      setToggleAnimation(true);
    }
  }, [open]);

  function close() {
    setToggleAnimation(false);
  }

  return (
    <div>
      {modalRoot
        && (
        <div>
          {createPortal(
            <div>
              {open
                && (
                <>
                  <animated.div
                    style={{ ...spring }}
                    className={styles['overlay-modal']}
                    onClick={close}
                  />
                  <animated.div
                    style={{ ...spring }}
                    className={styles.modal}
                  >
                    {children}
                    <button
                      type="button"
                      className={styles.close}
                      onClick={close}
                    >
                      <IoClose />
                    </button>
                  </animated.div>
                </>
                )}
            </div>,
            modalRoot,
          )}
        </div>
        )}
    </div>
  );
}

Modal.propTypes = {
  toggle: PropTypes.func.isRequired,
};

export default Modal;
