import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCongressAction, getNewsAction } from '../../actions/userActions';
import ActuCard from '../../components/ActuCard/ActuCard';
import ActuPanel from '../../components/ActuPanel/ActuPanel';

import styles from './Actus.module.scss';

export default function Actus() {
  const dispatch = useDispatch();
  const { congress, news } = useSelector((state) => state.user);
  const getData = () => {
    getCongressAction(dispatch);
    getNewsAction(dispatch);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={styles.container}>
      <h2>Actualités</h2>
      {congress?.length > 0 && (
      <>
        <h3 className={styles.congress}>Congrès à venir</h3>
        <div className={styles.cards}>
          {congress?.map((c) => <ActuCard key={c._id} card={c} type="congres" />)}
        </div>
      </>
      )}
      {news?.filter((n) => n.type !== 'Bon plan')?.length > 0 && (
      <>
        <h3 className={styles.news}>Autres actus</h3>
        <div className={styles.cards}>
          {news.filter((n) => n.type !== 'Bon plan')?.map((c) => <ActuCard key={c._id} card={c} type="news" />)}
        </div>
      </>
      )}
      {news?.filter((n) => n.type === 'Bon plan')?.length > 0 && (
      <>
        <h3 className={styles.plans}>Bons plans</h3>
        <div className={styles.cards}>
          {news.filter((n) => n.type === 'Bon plan')?.map((c) => <ActuCard key={c._id} card={c} type="plans" />)}
        </div>
      </>
      )}
      <ActuPanel />
    </div>
  );
}
