import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { loginAction, forgotPasswordAction, getUserAuthAction } from '../../actions/authActions';
import { TOOL_ID } from '../../actions';

import {
  InputEmail, InputPassword, Button, ErrorField,
} from '../../lib/HooksFormFields';

import logo from '../../assets/images/logo.svg';

import styles from './Auth.module.scss';
import { ERROR_AUTH } from '../../actions/types';

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userAuth, error, message } = useSelector((state) => state.auth);
  const {
    control,
    handleSubmit,
    getValues,
    setError,
    formState: {
      errors,
    },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const clearError = useCallback(() => {
    dispatch({
      type: ERROR_AUTH,
      payload: null,
    });
  }, [dispatch]);

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
  }, [dispatch]);

  function onSubmit(values) {
    setIsLoading(true);
    clearError();
    loginAction(dispatch, values);
  }

  function submitForgot() {
    clearError();
    if (!getValues('email')) {
      return setError('email', {
        type: 'manual',
        message: "L'email est requis",
      });
    }
    return forgotPasswordAction(dispatch, { email: getValues('email') });
  }

  useEffect(() => {
    clearError();
    return clearError();
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [error, message]);

  useEffect(() => {
    if (userAuth?.tools?.find((p) => p.tool === TOOL_ID)) {
      getUser();
      navigate('/majee/home');
    }
  }, [userAuth]);

  return (
    <div className={styles.container}>
      <form>
        <p className={styles.logo}>
          <img src={logo} alt="logo-majee" />
        </p>
        <InputEmail
          name="email"
          label="Mail"
          control={control}
          rules={{ required: "l'email est requis" }}
          className="login"
          required
          placeholder="Email"
        />
        <ErrorField message={errors?.email?.message || ''} />
        <InputPassword
          name="password"
          label="Mot de passe"
          control={control}
          className="login"
          rules={{ required: 'le mot de passe est requis' }}
          password
          placeholder="Mot de passe"
        />
        <ErrorField message={errors.password?.message || ''} />
        <div className={styles['btn-container']}>
          <Button
            handleClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
          >
            CONNEXION
          </Button>
          <button
            type="button"
            onClick={() => submitForgot()}
            className={styles.forgot}
          >
            Mot de passe oublié ?
          </button>
        </div>
        <p className={styles.message}>
          {error === 401
            && (
            <span className={styles.error}>
              L&apos;email ou le mot de passe
              <br />
              est incorrect.
            </span>
            )}
          {error?.data?.error === 'Your request could not be processed as entered. Please try again.'
            && (
            <span className={styles.error}>
              Votre email est incorrect
            </span>
            )}
          {message === 'Please check your email for the link to reset your password.'
            && (
            <span>
              Veuillez vérifier votre courrier électronique
              <br />
              pour réinitialiser votre mot de passe.
            </span>
            )}
        </p>
      </form>
    </div>
  );
}
