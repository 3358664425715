/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoArrowForward } from 'react-icons/io5';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { FaFile } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { putPlanningAction } from '../../actions/planningActions';
import { SET_ACTIVE_CR, SET_ACTIVE_CR_LIST } from '../../actions/types';
import { StyledSelect, InputText } from '../../lib/HooksFormFields';
import HtmlEditor from '../../lib/HooksFormFields/HtmlEditor/HtmlEditor';
import InputFile from '../InputFile/InputFile';
import styles from './CrPanel.module.scss';
import { deleteFileAction } from '../../actions/filesActions';

export default function CrPanel() {
  const dispatch = useDispatch();
  const {
    cr, list,
  } = useSelector((state) => state.planning);
  const [isEdit, setIsEdit] = useState(false);
  const [, setCrData] = useState('');
  const formatedDate = cr?.day?.date && new Intl.DateTimeFormat('fr', { dateStyle: 'full' }).format(new Date(cr?.day?.date));
  const {
    reset,
    setValue,
    control,
    handleSubmit,
    watch,
  } = useForm();
  const crForm = watch();

  useEffect(() => {
    if (cr?.day?.cr) {
      reset(cr?.day?.cr);
      setCrData(cr?.day?.cr);
      setIsEdit(false);
      dispatch({
        type: SET_ACTIVE_CR_LIST,
        payload: true,
      });
    }
  }, [cr?.day?.cr]);

  function close() {
    setIsEdit(false);
    setCrData('');
    dispatch({
      type: SET_ACTIVE_CR,
      payload: null,
    });
  }

  function updateDate() {
    if (crForm?.center?.value) {
      crForm.center = crForm?.center?.value;
    }
    if (crForm?.therapeuticArea?.value) {
      crForm.therapeuticArea = crForm?.therapeuticArea?.value;
    }
    if (crForm?.project?.value) {
      crForm.project = crForm?.project?.value;
    }
    if (crForm?.drug?.value) {
      crForm.drug = crForm?.drug?.value;
    }
    if (crForm?.therapeuticArea?.value) {
      crForm.therapeuticArea = crForm?.therapeuticArea?.value;
    }
    putPlanningAction(dispatch, cr.user, { day: { ...cr.day, cr: crForm } });
    setIsEdit(false);
  }

  function updateFiles(files) {
    putPlanningAction(dispatch, cr.user, {
      day: {
        ...cr.day,
        cr: {
          ...crForm,
          files,
        },
      },
    });
  }

  async function deleteFile(id) {
    await deleteFileAction(dispatch, id);
    const files = crForm.files.map((f) => f._id || f).filter((f) => f !== id);
    await updateFiles(files);
  }
  async function removeCr() {
    setIsEdit(false);
    crForm.files.forEach(async (f) => {
      await deleteFileAction(dispatch, f._id);
    });
    await putPlanningAction(dispatch, cr.user, { day: { ...cr.day, cr: null } });
    dispatch({
      type: SET_ACTIVE_CR,
      payload: null,
    });
  }

  return (
    <div className={`${styles.container} ${cr ? styles.active : ''}`}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.left}>
            <button
              type="button"
              onClick={() => close()}
              className={styles.close}
            >
              <IoArrowForward />
            </button>
            {!isEdit ? (
              <div className={styles.title}>
                <h3>{crForm.title}</h3>
              </div>
            )
              : (
                <div className={styles.title}>
                  <InputText
                    control={control}
                    name="title"
                    placeholder="Titre"
                    className="h3"
                  />
                </div>
              )}
          </div>
          <p>{formatedDate}</p>
          <div className={styles.edit}>
            {!isEdit
            && (
              <button
                type="button"
                onClick={() => setIsEdit(!isEdit)}
              >
                Editer
              </button>
            )}
          </div>
        </div>
        {list
          && (
          <div className={styles.cr}>
            {isEdit
              ? (
                <>
                  <div className={styles.infos}>
                    <div className={styles.row}>
                      <div className={styles.col}>
                        <div className={styles.field}>
                          <StyledSelect
                            label="Produit"
                            inline
                            name="drug"
                            widthLabel={120}
                            control={control}
                            options={list?.drugs.map((c) => ({ value: c._id, label: c.name }))}
                          />
                        </div>
                        <div className={styles.field}>
                          <StyledSelect
                            label="Aire thérapeutique"
                            inline
                            name="therapeuticArea"
                            widthLabel={120}
                            control={control}
                            options={list?.therapeuticAreas.map(
                              (c) => ({ value: c._id, label: c.name }),
                            )}
                          />
                        </div>
                      </div>
                      <div className={styles.col}>
                        <div className={styles.field}>
                          <StyledSelect
                            label="Projet"
                            inline
                            name="project"
                            widthLabel={120}
                            control={control}
                            options={list?.projects?.map((c) => ({ value: c._id, label: c.name }))}
                          />
                        </div>
                        <div className={styles.field}>
                          <StyledSelect
                            label="Établissement"
                            inline
                            name="center"
                            widthLabel={120}
                            control={control}
                            options={list?.centers?.map((c) => ({ value: c._id, label: c.name }))}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.block}>
                    <p className={styles.label}>Compte-rendu</p>
                    <HtmlEditor
                      onChange={(html) => setValue('content', html)}
                      defaultValue={watch('content')}
                    />
                  </div>
                  <div className={styles.block}>
                    <p className={styles.label}>Pièce jointe</p>
                    <div className={styles.files}>
                      {crForm?.files?.filter((f) => f?._id).map(
                        (f) => (
                          <div key={f._id} className={styles.file}>
                            <a href={`${process.env.REACT_APP_API}/${f.path}`} target="_blank" rel="noreferrer">
                              <FaFile />
                              <p>{f.name}</p>
                            </a>
                            <button
                              type="button"
                              onClick={() => deleteFile(f._id)}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>
                        ),
                      )}
                    </div>
                    <InputFile
                      name="file"
                      label="Ajouter une pièce jointe"
                      handleChange={(id) => updateFiles(
                        [...crForm.files.map((f) => f._id || f), id],
                      )}
                    />
                  </div>
                  <div className={styles.actions}>
                    <button
                      type="button"
                      className={styles.delete}
                      onClick={() => removeCr()}
                    >
                      supprimer
                    </button>
                    <button
                      type="button"
                      className={styles.submit}
                      onClick={handleSubmit(updateDate)}
                    >
                      Valider
                    </button>
                  </div>
                </>
              )
              : (
                <>
                  <div className={styles.infos}>
                    <div className={styles.row}>
                      <div className={styles.col}>
                        <div className={styles.field}>
                          <label>
                            Produit
                          </label>
                          <p>{list?.drugs?.find((c) => c._id === watch('drug') || c._id === watch('drug')?.value)?.name}</p>
                        </div>
                        <div className={styles.field}>
                          <label>
                            Aire thérapeutique
                          </label>
                          <p>{list?.therapeuticAreas?.find((c) => c._id === watch('therapeuticArea') || c._id === watch('therapeuticArea')?.value)?.name}</p>
                        </div>
                      </div>
                      <div className={styles.col}>
                        <div className={styles.field}>
                          <label>
                            Projet
                          </label>
                          <p>{list?.projects?.find((c) => c._id === watch('project') || c._id === watch('project')?.value)?.name}</p>
                        </div>
                        <div className={styles.field}>
                          <label>
                            Établissement
                          </label>
                          <p>{list?.centers?.find((c) => c._id === watch('center') || c._id === watch('center')?.value)?.name}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {watch('content') && (
                  <div className={styles.block}>
                    <p className={styles.label}>Compte-rendu</p>
                    <div
                      className={styles.html}
                      dangerouslySetInnerHTML={{ __html: watch('content') }}
                    />
                  </div>
                  )}
                  <div className={styles.block}>
                    <p className={styles.label}>Pièce jointe</p>
                    <div className={styles.files}>
                      {crForm?.files?.filter((f) => f?._id).map(
                        (f) => (
                          <div key={f._id} className={styles.file}>
                            <a href={`${process.env.REACT_APP_API}/${f.path}`} target="_blank" rel="noreferrer">
                              <FaFile />
                              <p>{f.name}</p>
                            </a>
                          </div>
                        ),
                      )}
                    </div>
                    {' '}
                  </div>
                </>
              )}

          </div>
          )}
      </div>
    </div>
  );
}
