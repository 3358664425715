import {
  ERROR_PROJECTS,
  MESSAGE_PROJECTS,
  GET_PROJECTS,
  PUT_PROJECT,
  GET_PROJECT,
  EDIT_TASK,
  PROJECT_IS_LOADING,
} from '../actions/types';

const initialState = {
  error: null,
  message: null,
  projectsList: [],
  project: null,
  isLoading: false,
  task: null,
};

export default function projectReducer(state = initialState, action = {}) {
  const list = [...state.projectsList.map((n) => ({ ...n }))];
  const index = list.findIndex((n) => n._id === action.payload?._id);
  switch (action.type) {
    case PUT_PROJECT:
      if (index !== -1) {
        list[index] = { ...action.payload };
      }
      return {
        ...state, projectsList: [...list], project: { ...action.payload }, isLoading: false,
      };
    case GET_PROJECT:
      return { ...state, project: action.payload, isLoading: false };
    case GET_PROJECTS:
      return {
        ...state, projectsList: action.payload, project: null, isLoading: false,
      };
    case EDIT_TASK:
      return { ...state, task: action.payload };
    case PROJECT_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case MESSAGE_PROJECTS:
      return { ...state, message: action.payload };
    case ERROR_PROJECTS:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
}
