import { isSameDay } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { planningOptions } from '../PlanningRow/PlanningRow';
import styles from './HomePlanning.module.scss';

function Step({ step }) {
  const activeStep = planningOptions.find((opt) => opt.value === step.type);
  if (!activeStep) {
    return null;
  }
  return (
    <div className={`${styles.step} ${styles.active}`}>
      <div className={styles.bar} style={{ backgroundColor: activeStep.color }} />
      <img src={activeStep.icon} alt={activeStep.label} />
      <p>{activeStep.label}</p>
      {' '}
      {step.period !== 'all'
        && (
        <p>
          (
          {step.period === 'am' ? 'Matiné' : 'Après-midi'}
          )
        </p>
        )}
    </div>
  );
}

function PlanningList({ steps = [], label }) {
  return (
    <div className={styles.list}>
      <label>{label}</label>
      {steps.length > 0
        ? (
          <>
            {steps.sort(
              (a, b) => a.period.localeCompare(b.period),
            ).map((s) => <Step key={s._id} step={s} />)}
          </>
        )
        : (
          <div className={`${styles.step}`}>
            <p>
              Rien de prévu
              {' '}
              {label.toLowerCase()}
              {' '}
              !
            </p>
          </div>
        )}
    </div>
  );
}

export default function HomePlanning() {
  const {
    planning,
  } = useSelector((state) => state.planning);
  const { userAuth } = useSelector((state) => state.auth);
  const [userPlanning, setUserPlanning] = useState();

  useEffect(() => {
    if (planning.length > 0) {
      const planningFiltered = planning?.find((p) => p.user._id === userAuth._id);
      const startDate = new Date();
      const endDate = new Date(new Date(startDate).setDate(startDate.getDate() + 1));
      const data = [];
      planningFiltered?.days?.forEach((d) => {
        const step = { ...d };
        if (isSameDay(new Date(step.date), startDate)) {
          step.day = 'today';
          data.push(step);
        }
        if (isSameDay(new Date(step.date), endDate)) {
          step.day = 'tomorrow';
          data.push(step);
        }
      });
      setUserPlanning(data);
    }
  }, [planning, userAuth]);

  return (
    <div className={styles.container}>
      {userPlanning
      && (
      <>
        <PlanningList
          label="Aujourd'hui"
          steps={userPlanning?.filter((s) => s.day === 'today')}
        />
        <PlanningList
          label="Demain"
          steps={userPlanning?.filter((s) => s.day === 'tomorrow')}
        />
      </>
      )}
    </div>
  );
}
