// AUTH ACTIONS
export const ERROR_AUTH = 'ERROR_AUTH';
export const MESSAGE_AUTH = 'MESSAGE_AUTH';
export const USER_AUTH = 'USER_AUTH';
export const SET_TOAST = 'SET_TOAST';

// AUTH ACTIONS
export const ERROR_USER = 'ERROR_USER';
export const MESSAGE_USER = 'MESSAGE_USER';
export const GET_TOOLS = 'GET_TOOLS';
export const GET_CONGRESS = 'GET_CONGRESS';
export const GET_NEWS = 'GET_NEWS';
export const GET_GIF = 'GET_GIF';
export const GET_METEO = 'GET_METEO';
export const SET_ACTIVE_ACTU = 'SET_ACTIVE_ACTU';

// NOTIF ACTIONS
export const ERROR_NOTIFS = 'ERROR_NOTIFS';
export const MESSAGE_NOTIFS = 'MESSAGE_NOTIFS';
export const GET_NOTIFS = 'GET_NOTIFS';
export const PUT_NOTIF = 'PUT_NOTIF';

// PROJECT ACTIONS
export const ERROR_PROJECTS = 'ERROR_PROJECTS';
export const MESSAGE_PROJECTS = 'MESSAGE_PROJECTS';
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT = 'GET_PROJECT';
export const PUT_PROJECT = 'PUT_PROJECT';
export const PROJECT_IS_LOADING = 'PROJECT_IS_LOADING';
export const EDIT_TASK = 'EDIT_TASK';

// PLANNING ACTIONS
export const ERROR_PLANNINGS = 'ERROR_PLANNINGS';
export const MESSAGE_PLANNINGS = 'MESSAGE_PLANNINGS';
export const GET_PLANNING = 'GET_PLANNING';
export const PUT_PLANNING = 'PUT_PLANNING';
export const SET_ACTIVE_CR = 'SET_ACTIVE_CR';
export const SET_ACTIVE_CR_LIST = 'SET_ACTIVE_CR_LIST';
export const GET_PLANNING_LIST = 'GET_PLANNING_LIST';
export const GET_CR_LIST = 'GET_CR_LIST';

// PROJECT ACTIONS
export const ERROR_TODOS = 'ERROR_TODOS';
export const MESSAGE_TODOS = 'MESSAGE_TODOS';
export const GET_TODOS = 'GET_TODOS';
export const POST_TODO = 'POST_TODO';
export const PUT_TODO = 'PUT_TODO';
export const DELETE_TODO = 'DELETE_TODO';

// FILE ACTIONS
export const ERROR_FILE = 'ERROR_FILE';
export const DELETE_FILE = 'DELETE_FILE';
export const POST_FILE = 'POST_FILE';
