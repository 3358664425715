/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';
import styles from './input-email.module.scss';

function InputEmail({
  name,
  control,
  widthLabel = '',
  rules = {},
  inline = false,
  label = null,
  placeholder = '',
  className,
}) {
  const {
    field: {
      value = '',
      ref,
      onChange,
      onBlur,
    },
  } = useController({
    name,
    control,
    rules,
    defaultValue: '',
  });
  return (
    <div className={inline ? `${className ? styles[className] : ''} ${styles['container-input-email']} ${styles.inline}` : `${className ? styles[className] : ''} ${styles['container-input-email']}`}>
      { label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
      <input
        ref={ref}
        value={value}
        onBlur={onBlur}
        onChange={(e) => onChange(e.target.value)}
        className={styles.input}
        type="email"
        placeholder={placeholder}
      />
    </div>

  );
}

InputEmail.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
};

export default InputEmail;
