import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import authReducer from '../reducers/authReducer';
import userReducer from '../reducers/userReducer';
import notifictionReducer from '../reducers/notificationReducer';
import projectReducer from '../reducers/projectReducer';
import planningReducer from '../reducers/planningReducer';
import todoReducer from '../reducers/todoReducer';
import filesReducer from '../reducers/filesReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  notifs: notifictionReducer,
  projects: projectReducer,
  planning: planningReducer,
  todos: todoReducer,
  files: filesReducer,
});

export default configureStore({ reducer: rootReducer });
