import {
  ERROR_USER,
  MESSAGE_USER,
  GET_TOOLS,
  GET_GIF,
  GET_CONGRESS,
  GET_NEWS,
  GET_METEO,
  SET_ACTIVE_ACTU,
} from '../actions/types';

const initialState = {
  error: null,
  message: null,
  tools: null,
  congress: null,
  news: null,
  gif: null,
  meteo: null,
  activeActu: null,
};

export default function userReducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_METEO:
      return { ...state, meteo: action.payload };
    case GET_NEWS:
      return { ...state, news: action.payload };
    case GET_CONGRESS:
      return { ...state, congress: action.payload };
    case SET_ACTIVE_ACTU:
      return { ...state, activeActu: action.payload };
    case GET_TOOLS:
      return { ...state, tools: action.payload };
    case GET_GIF:
      return { ...state, gif: action.payload };
    case MESSAGE_USER:
      return { ...state, message: action.payload };
    case ERROR_USER:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
}
