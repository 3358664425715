import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { putNotificationAction } from '../../actions/notificationsActions';
import { hexToRgba } from '../../utils/utils';
import styles from './Notifications.module.scss';

function ToolIcon({ name }) {
  const { tools } = useSelector((state) => state.user);
  const tool = tools?.toolsList
    && tools?.toolsList?.find((t) => t?.title?.toLowerCase() === name?.toLowerCase());
  if (tool) {
    return (
      <div
        className={styles.icon}
        style={{ backgroundColor: hexToRgba(tool.color, 0.2) }}
      >
        <span
          style={{ color: tool.color }}
        >
          {tool.title[0]}
        </span>
      </div>
    );
  }
  return null;
}

export default function Notifications({ notifications }) {
  const formatDate = (date) => new Intl.DateTimeFormat('fr', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(date));
  const dispatch = useDispatch();

  function handleNotifiction(notif) {
    if (notif.type === 'question') {
      window.open(`https://medocta.octapharma.fr/question/${notif.objId}`, '_blank');
    }
    putNotificationAction(dispatch, notif._id, { new: false });
  }

  return (
    <div className={styles.container}>
      {notifications?.map((n) => (
        <button
          key={n._id}
          type="button"
          className={styles.notifiction}
          onClick={() => handleNotifiction(n)}
        >
          {n.new && <div className={styles.new} />}
          <ToolIcon name={n.product} />
          <div className={styles.infos}>
            <h5>{n.title}</h5>
            <p>{formatDate(n.createdAt)}</p>
          </div>
        </button>
      ))}
    </div>
  );
}
