/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import styles from './styled-select.module.scss';
import styleJs from './style';

function StyledSelect({
  name,
  control,
  rules,
  // eslint-disable-next-line no-unused-vars
  noRadius = true,
  widthLabel = null,
  inline = false,
  placeholder = '',
  label = null,
  isCreatable = false,
  formatCreateLabel = null,
  ...props
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      onFocus={() => {
        console.log('controller focus');
      }}
      render={({
        field,
      }) => (
        <div className={inline ? `${styles['container-select']} ${styles.inline}` : `${styles['container-select']}`}>
          { label && (
          <label style={widthLabel ? { minWidth: widthLabel } : {}}>
            {label}
          </label>
          )}
          {!isCreatable
            ? (
              <Select
                {...field}
                {...props}
                value={props.options.find((opt) => opt.value === field.value)}
                noRadius={noRadius}
                className={`${styles.select}`}
                placeholder={placeholder}
                styles={styleJs}
              />
            )
            : (
              <CreatableSelect
                {...field}
                {...props}
                noRadius={noRadius}
                className={`${styles.select}`}
                formatCreateLabel={formatCreateLabel}
                placeholder={placeholder}
                styles={styleJs}
              />
            )}
        </div>
      )}
    />
  );
}

StyledSelect.propTypes = {
  name: PropTypes.string.isRequired,
};

export default StyledSelect;
