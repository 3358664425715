import React, { useEffect, useCallback } from 'react';
import {
  Routes, Route, useLocation, useNavigate, Outlet,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getUserAuthAction } from './actions/authActions';
import { ERROR_AUTH, USER_AUTH } from './actions/types';

import { setVhUnit } from './utils/utils';

import Toaster from './lib/Toaster/Toaster';

import Index from './pages/Auth/SwitchIndex';
import Login from './pages/Auth/Login';
import Page404 from './pages/Page404/Page404';
import Nav from './components/Nav/Nav';
import Home from './pages/Home/Home';
import Actus from './pages/Actus/Actus';
import Notifs from './pages/Notifs/Notifs';
import Projects from './pages/Projects/Projects';
import Planning from './pages/Planning/Planning';
import Tools from './pages/Tools/Tools';

function Layout({ logout }) {
  return (
    <div>
      <Nav logout={logout} />
      <div>
        <Outlet />
      </div>
    </div>
  );
}

export default function index() {
  const { error, userAuth, toast } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isLogin = pathname === '/login' || pathname === '/';
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
  }, [dispatch]);

  function logout() {
    navigate('/login');
    localStorage.removeItem('token');
    dispatch({
      type: ERROR_AUTH,
      payload: null,
    });
    dispatch({
      type: USER_AUTH,
      payload: null,
    });
  }

  useEffect(() => {
    if (token) {
      getUser();
    } else {
      navigate('/login');
    }
    setVhUnit();
  }, []);

  useEffect(() => {
    if (!isLogin && error === 401) {
      logout();
    }
  }, [error]);

  return (
    <>
      <Routes>
        <Route index element={<Index />} />
        <Route path="login" element={<Login />} />
        {userAuth
        && (
        <Route path="/majee" element={<Layout logout={() => logout()} />}>
          <Route path="home" element={<Home />} />
          <Route path="planning" element={<Planning />} />
          <Route path="actus" element={<Actus />} />
          <Route path="project" element={<Projects />} />
          <Route path="tool" element={<Tools />} />
          <Route path="notifications" element={<Notifs />} />
        </Route>
        )}
        <Route path="*" element={<Page404 />} />

      </Routes>
      <Toaster toast={toast} />
    </>
  );
}
