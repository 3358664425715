import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// Contect & actions

import { deleteFileAction, postFileAction } from '../../actions/filesActions';

// Styles
import styles from './InputFile.module.scss';
import { ErrorField } from '../../lib/HooksFormFields';
import { ERROR_FILE, POST_FILE } from '../../actions/types';

function InputFile({
  name,
  activeFile,
  label = 'Ajouter',
  handleChange,
  className = '',
}) {
  const dispatch = useDispatch();
  const { file, error } = useSelector((state) => state.files);
  const [uploadedFile, setUploadedFile] = useState();
  const [fileError, setFileError] = useState();

  const clearFile = useCallback(() => {
    dispatch({
      type: POST_FILE,
      payload: null,
    });
    dispatch({
      type: ERROR_FILE,
      payload: null,
    });
  }, [dispatch]);

  useEffect(() => {
    clearFile();
    return () => {
      clearFile();
    };
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    if (file?._id && uploadedFile) {
      if (uploadedFile) document.getElementById(name).value = '';
      setUploadedFile();
      setFileError();
      handleChange(file._id);
      dispatch({
        type: POST_FILE,
        payload: null,
      });
    }
    // eslint-disable-next-line
  },[file]);

  useEffect(() => {
    if (error && error.data && error.data.error) {
      if (error.data.error === 'Extension not allowed.') {
        setFileError('Extension not allowed.');
        setUploadedFile();
      }
    }
    // eslint-disable-next-line
  },[error]);

  function handleChangeFile(fileData) {
    setUploadedFile(fileData);
    setFileError();
    if (activeFile?._id) deleteFileAction(dispatch, activeFile._id);
    if (fileData.name) {
      const formData = new FormData();
      formData.append('file', fileData);
      formData.append('name', fileData.name);
      formData.append('alt', fileData.name.split('.')[0]);
      postFileAction(dispatch, formData);
    }
  }

  return (
    <div className={styles.container}>
      <div className={`${styles.input} ${className}`}>
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
        <input type="file" name={name} onChange={(e) => handleChangeFile(e.target.files[0])} id={name} />
      </div>
      {fileError && <ErrorField message={fileError} />}
    </div>
  );
}

InputFile.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default InputFile;
