import React from 'react';
import { format } from 'date-fns';
import { IoClose } from 'react-icons/io5';
import { FiCalendar } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { SET_ACTIVE_ACTU } from '../../actions/types';
import styles from './ActuPanel.module.scss';
import { API_URL } from '../../actions';

export default function ActuPanel() {
  const dispatch = useDispatch();
  const { activeActu } = useSelector((state) => state.user);

  function close() {
    dispatch({
      type: SET_ACTIVE_ACTU,
      payload: null,
    });
  }

  return (
    <div className={`${styles.container} ${activeActu ? styles.active : ''}`}>
      <button
        type="button"
        onClick={() => close()}
        className={styles.close}
      >
        <IoClose />
      </button>
      {activeActu
      && (
        <div className={styles.content}>
          <div className={styles.title}>
            <h3>{activeActu?.title || activeActu?.name}</h3>
            {activeActu.type === 'congres'
                && (
                <div className={styles.dates}>
                  <div className={styles.date}>
                    <FiCalendar color="#4F3CC8" />
                    {activeActu?.startDate && (
                    <p>
                      Début :
                      {' '}
                      {format(new Date(activeActu?.startDate), 'dd/MM/yyyy')}
                    </p>
                    )}
                  </div>
                  <div className={styles.date}>
                    <FiCalendar color="#4F3CC8" />
                    {activeActu?.endDate && (
                    <p>
                      Fin :
                      {' '}
                      {format(new Date(activeActu?.endDate), 'dd/MM/yyyy')}
                    </p>
                    )}
                  </div>
                </div>
                )}
            {activeActu.type === 'news'
                && (
                <div className={`${styles.dates} ${styles.news}`}>
                  <div className={styles.date}>
                    <FiCalendar color="#11B1C7" />
                    {activeActu?.date && (
                    <p>
                      {format(new Date(activeActu?.date), 'dd/MM/yyyy')}
                    </p>
                    )}
                  </div>
                  <div>
                    <p className={styles.author}>
                      {activeActu?.author?.profile?.firstName}
                      {' '}
                      {activeActu?.author?.profile?.lastName}
                    </p>
                  </div>
                </div>
                )}
          </div>
          {activeActu?.image?.path
            && (
            <div className={styles.image}>
              <img src={`${API_URL}/${activeActu.image.path}`} alt={activeActu?.image?.alt} />
            </div>
            )}
          {activeActu.description && <p className={styles.text}>{activeActu.description}</p>}
          {activeActu.texte && (
          <div
            className={styles.text}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: activeActu.texte }}
          />
          )}
        </div>

      )}
    </div>
  );
}
