import React, {
  useCallback, useEffect, useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { MdOutlineLogout } from 'react-icons/md';
import { BiBell } from 'react-icons/bi';

import { hexToRgba } from '../../utils/utils';
import { getNotificationsAction } from '../../actions/notificationsActions';
import { getGifAction, getMeteoAction, getToolsAction } from '../../actions/userActions';

import logo from '../../assets/images/logo-nav.svg';
import styles from './Nav.module.scss';

function Toggle({ isActive, setIsActive }) {
  return (
    <button
      type="button"
      className={`${styles.toggle} ${isActive ? styles.active : ''}`}
      onClick={() => setIsActive(!isActive)}
    >
      <span />
      <span />
      <span />
    </button>
  );
}

const links = [
  {
    path: '/majee/home',
    label: 'Accueil',
  },
  {
    path: '/majee/planning',
    label: 'Planning',
  },
  {
    path: '/majee/actus',
    label: 'Actualités',
  },
  {
    path: '/majee/tool',
    label: 'OUTILS',
  },
  {
    path: '/majee/project',
    label: 'PROJETS',
  },
  // {
  //   path: '/majee/profile',
  //   label: 'CONTACT',
  // },
];

export default function Nav({ logout }) {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { userAuth } = useSelector((state) => state.auth);
  const { tools, gif } = useSelector((state) => state.user);
  const { notifications } = useSelector((state) => state.notifs);

  const isNewNotifs = notifications?.find((n) => n.new);

  const { firstName, lastName } = userAuth;
  const toolsList = tools?.toolsList;
  const [isActive, setIsActive] = useState(false);
  const day = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(new Date());

  const getData = useCallback(() => {
    if (!gif) {
      getGifAction(dispatch, day);
      getMeteoAction(dispatch);
    }
  }, [dispatch]);

  const getTools = useCallback(() => {
    getToolsAction(dispatch, userAuth);
    getNotificationsAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!toolsList && userAuth?.tools?.length) {
      getTools();
    }
  }, [userAuth?.tools]);
  return (
    <nav className={styles.container}>
      <div className={styles.menu}>
        <NavLink to={`/majee/home${search}`} className={styles.logo}>
          <img src={logo} alt="logo-majee" />
        </NavLink>
        <div className={`${styles.panel} ${isActive ? styles.active : ''}`}>
          <ul className={isActive ? styles.active : ''}>
            {links.map((l) => (
              <li key={l.label}>
                <NavLink
                  to={l.path + search}
                  onClick={() => setIsActive(false)}
                  className={(navData) => (navData.isActive ? styles['active-link'] : '')}
                >
                  {l.label}
                </NavLink>
              </li>
            ))}
          </ul>
          <div className={styles.tools}>
            {toolsList
              ?.filter((tool) => userAuth?.favoriteTools?.find((t) => t === tool._id))
              ?.map((t) => (
                <a
                  key={t._id}
                  href={t.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className={styles.icon}
                    style={{ backgroundColor: hexToRgba(t.color, 0.2) }}
                  >
                    <span
                      style={{ color: t.color }}
                    >
                      {t.title[0]}
                    </span>
                  </div>
                  <p>{t.title}</p>
                </a>
              ))}
          </div>
          <div className={`${styles.profile} ${styles.mobile}`}>
            <p>
              {firstName}
              {' '}
              {lastName}
            </p>
            <button
              type="button"
              className={styles.logout}
              onClick={() => logout()}
            >
              <MdOutlineLogout />
            </button>
          </div>
        </div>
      </div>
      <div>
        <div className={`${styles.new} ${isNewNotifs ? styles.active : ''}`} />
        <NavLink
          to="/majee/notifications"
          className={styles.notifs}
          onClick={() => setIsActive(false)}
        >
          <BiBell />
        </NavLink>
        <Toggle
          isActive={isActive}
          setIsActive={setIsActive}
        />
        <div className={`${styles.profile} ${styles.desktop}`}>
          <p>
            {firstName}
            {' '}
            {lastName}
          </p>
          <button
            type="button"
            className={styles.logout}
            onClick={() => logout()}
          >
            <MdOutlineLogout />
          </button>
        </div>
      </div>
    </nav>
  );
}
