import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationsAction } from '../../actions/notificationsActions';
import Notifications from '../../components/Notifications/Notifications';

import styles from './Notifs.module.scss';

export default function Notifs() {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notifs);
  const getData = () => {
    getNotificationsAction(dispatch);
  };

  const newNotifs = notifications?.filter((n) => n.new);
  const oldNotifs = notifications?.filter((n) => !n.new);

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={styles.container}>
      <h2>Notifications</h2>
      <div className={styles.row}>
        <div className={styles.col}>
          <h3 className={styles.new}>Non lues</h3>
          {newNotifs.length > 0
            ? <Notifications notifications={newNotifs} />
            : <p>Vous n’avez pas de nouvelles notifications</p>}
        </div>
        <div className={styles.col}>
          <h3>lues</h3>
          {oldNotifs.length > 0
            ? <Notifications notifications={oldNotifs} />
            : <p>Vous n’avez pas de notifications</p>}
        </div>

      </div>
    </div>
  );
}
