import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { TOOL_ID } from '../../actions';
import { tasksStatusOptions } from '../../constants/constants';

import {
  Checkbox,
  DatePicker, InputFormatNumber, StyledSelect, Textarea,
} from '../../lib/HooksFormFields';
import styles from './ProjectTaskForm.module.scss';

export default function ProjectTaskForm({
  task,
  tasks,
  submit,
}) {
  const {
    control,
    setValue,
    watch,
    getValues,
    // handleSubmit,
    // formState: {
    //   errors,
    // },
  } = useForm();

  const { userAuth } = useSelector((state) => state.auth);
  const isAdmin = userAuth.tools.find((t) => t.tool === TOOL_ID)?.role === 'Admin';
  const {
    list,
  } = useSelector((state) => state.planning);
  const usersOptions = list?.foundUsers?.map((u) => ({ value: u._id, label: `${u?.profile?.firstName} ${u?.profile?.lastName}` }));
  const formationDateRef = useRef();
  const dueDateRef = useRef();
  function handleSubmitTask() {
    let tasksList = [...tasks];
    const values = getValues();
    const index = tasksList.findIndex((t) => t._id === task?._id);
    if (index >= 0) {
      tasksList[index] = {
        ...tasksList[index],
        ...values,
      };
    } else {
      tasksList = [...tasksList, values];
    }
    submit({ tasks: tasksList });
  }

  useEffect(() => {
    if (task) {
      const propreties = [
        'status',
        'text',
        'priority',
        'duration',
        'formationNeeded',
        'assignment',
      ];
      const dates = [
        'formationDate',
        'dueDate',
      ];
      propreties.forEach((p) => {
        if (task[p]) {
          setValue(p, task[p]);
        }
      });
      dates.forEach((p) => {
        if (task[p]) {
          setValue(p, new Date(task[p]));
        }
      });
    }
  }, [task]);
  console.log(task);
  return (
    <div className={styles.container}>
      <h2>{task?._id ? "Modification d'une tâche" : 'Ajout d’une tâche' }</h2>
      <div className={styles.row}>
        <div className={styles['input-state']}>
          <label>Status</label>
          <div className={styles.input}>
            <StyledSelect
              name="status"
              control={control}
              value={tasksStatusOptions.find((o) => o.value === watch('status'))}
              onChange={(opt) => setValue('status', opt.value)}
              placeholder="Séléctionner un status..."
              options={tasksStatusOptions}
            />
          </div>
        </div>
        {watch('status')
          && (
          <div className={`${styles.state} ${styles[watch('status')]}`}>
            <span>{tasksStatusOptions.find((s) => s.value === watch('status'))?.label}</span>
          </div>
          )}
      </div>
      <div className={styles['input-block']}>
        <label>Texte</label>
        <div className={styles.input}>
          <Textarea
            name="text"
            control={control}
            className="primary"
            placeholder=""
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles['input-block']}>
          <label>Priorité</label>
          <div className={styles.priorities}>
            <button
              type="button"
              className={`${styles.hight} ${watch('priority') === 1 ? styles.active : ''}`}
              onClick={() => setValue('priority', 1)}
            >
              Haute
            </button>
            <button
              type="button"
              className={`${styles.medium} ${watch('priority') === 2 ? styles.active : ''}`}
              onClick={() => setValue('priority', 2)}
            >
              Moyenne
            </button>
            <button
              type="button"
              className={`${styles.low} ${watch('priority') === 3 ? styles.active : ''}`}
              onClick={() => setValue('priority', 3)}
            >
              Basse
            </button>
          </div>
        </div>
        <div className={styles['input-block']}>
          <label>Durée</label>
          <div className={styles.input}>
            <InputFormatNumber
              name="duration"
              control={control}
              className="primary"
              placeholder="Durée (en mois)"
            />
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <h3>Formation</h3>
        <div className={styles.row}>
          <div className={styles['input-block']}>
            <div className={styles.checkbox}>
              <Checkbox
                name="formationNeeded"
                control={control}
              />
              <label className={styles.label}>Formation nécéssaire</label>
            </div>
          </div>
          <div className={styles['input-block']}>
            <div className={styles.input}>
              <DatePicker
                ref={formationDateRef}
                name="formationDate"
                control={control}
                widthLabel="30%"
                placeholder="Date de la formation"
              />
            </div>
          </div>
        </div>
      </div>
      {isAdmin && usersOptions
      && (
        <div className={styles.section}>
          <h3>Affectation</h3>
          <div className={styles['input-state']}>
            <div className={styles.input}>
              <StyledSelect
                name="assignment"
                isMulti
                control={control}
                value={usersOptions.filter((opt) => watch('assignment')?.find((a) => a === opt.value))}
                onChange={(opt) => setValue('assignment', opt.map((v) => v.value))}
                placeholder="Séléctionner..."
                options={usersOptions || []}
              />
            </div>
          </div>
        </div>
      )}
      <div className={styles.section}>
        <div className={styles['input-block']}>
          <label>Livraison</label>
          <div className={styles.input}>
            <DatePicker
              ref={dueDateRef}
              name="dueDate"
              control={control}
              placeholder="Date de la livraison"
            />
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <button
          type="button"
          onClick={() => handleSubmitTask()}
        >
          Enregistrer la tâche
        </button>
      </div>
    </div>
  );
}
