import axios from 'axios';
import { getData, putData } from './index';
import {
  ERROR_USER, GET_GIF, GET_TOOLS, GET_METEO, GET_CONGRESS, GET_NEWS, USER_AUTH,
} from './types';

export async function getToolsAction(dispatch, user) {
  const url = '/tool';
  let toolsList;
  const userTools = user.tools?.map((t) => t.tool);
  const { division } = user;
  await getData(ERROR_USER, url, dispatch, true).then((response) => {
    toolsList = response.tools
      .filter((t) => t._id !== '6374e80d96463a6463ea7bd2')
      .filter((t) => {
        if (division.find((d) => t.division.find((td) => td === d))) {
          return t;
        }
        if (userTools?.find((id) => id === t._id)) {
          return t;
        }
        return null;
      });
  });
  let types;
  await getData(ERROR_USER, `${url}/list/type`, dispatch, true).then((response) => {
    types = response.types;
  });
  dispatch({
    type: GET_TOOLS,
    payload: { toolsList, types },
  });
}

export async function getGifAction(dispatch, keyword) {
  const giphy = {
    baseURL: 'https://api.giphy.com/v1/gifs/',
    apiKey: '0UTRbFtkMxAplrohufYco5IY74U8hOes',
    tag: keyword,
    type: 'random',
    rating: 'pg-13',
  };
  const giphyURL = encodeURI(
    `${giphy.baseURL + giphy.type
    }?api_key=${
      giphy.apiKey
    }&tag=${
      giphy.tag
    }&rating=${
      giphy.rating}`,
  );
  const res = await axios.get(giphyURL, {});
  const gif = res?.data?.data?.images?.original?.url;
  dispatch({
    type: GET_GIF,
    payload: gif,
  });
}

export async function getMeteoAction(dispatch) {
  navigator.geolocation.getCurrentPosition(async (pos) => {
    const { latitude, longitude } = pos.coords;

    const url = `https://api.open-meteo.com/v1/forecast?latitude=${latitude}&longitude=${longitude}&current_weather=true`;
    const weatherData = await axios.get(url, {});
    const weather = weatherData?.data;
    const mapUrl = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=fr"`;
    const mapData = await axios.get(mapUrl, {});
    const data = {};
    const code = weather?.current_weather?.weathercode;
    if (code === 0) {
      data.icon = 'clear';
      data.message = 'Ciel clair';
    }
    if (code >= 1 && code <= 3) {
      data.icon = 'cloudy';
      data.message = 'Partielement nuageux';
    }
    if (code >= 45 && code <= 57) {
      data.icon = 'showers';
      data.message = 'Averses';
    }
    if (code >= 61 && code <= 94) {
      data.icon = 'rain';
      data.message = 'Pluie';
    }
    if (code >= 95 && code <= 99) {
      data.icon = 'storm';
      data.message = 'Orage';
    }
    data.temperature = weather.current_weather.temperature;
    data.city = mapData.data.city;
    dispatch({
      type: GET_METEO,
      payload: data,
    });
  });
}

export async function getCongressAction(dispatch) {
  const url = '/congress';
  let congress;
  await getData(ERROR_USER, url, dispatch, true).then((response) => {
    congress = response.congress
      .filter((t) => new Date(t.startDate) > new Date());
  });
  dispatch({
    type: GET_CONGRESS,
    payload: congress,
  });
}

const addDays = (days) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};

export async function getNewsAction(dispatch) {
  const url = '/news';
  let news;
  await getData(ERROR_USER, url, dispatch, true).then((response) => {
    const nextDate = addDays(31);
    news = response.news
      .filter((n) => n.status === 'published')
      .filter((n) => new Date(n.date) > new Date() && new Date(n.date) < nextDate);
  });
  dispatch({
    type: GET_NEWS,
    payload: news,
  });
}

export async function putFavoritesAction(
  dispatch,
  data,
) {
  const url = '/profile/favorite';
  const promise = await putData(USER_AUTH, ERROR_USER, url, dispatch, data, true)
    .then((response) => {
      console.log(response);
      dispatch({
        type: USER_AUTH,
        payload: response.user,
      });
    });
  return promise;
}
