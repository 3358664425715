import { format } from 'date-fns';
import React from 'react';
// import { BiUser } from 'react-icons/bi';
import { TiArrowForwardOutline } from 'react-icons/ti';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EDIT_TASK } from '../../actions/types';
import { tasksStatusOptions } from '../../constants/constants';
import styles from './TaskCard.module.scss';

export default function TaskCard({
  task,
  isEdit = true,
  projectId = null,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleEdit() {
    if (projectId) {
      navigate(`/majee/project?project=${projectId}`);
      return dispatch({
        type: EDIT_TASK,
        payload: task,
      });
    }
    return dispatch({
      type: EDIT_TASK,
      payload: task,
    });
  }

  return (
    <button
      type="button"
      className={`${styles.container} ${isEdit ? styles['is-edit'] : ''}`}
      onClick={() => handleEdit()}
    >
      <div className={styles.text}>
        <p>{task.text}</p>
      </div>
      <div className={styles.row}>
        {/* <div className={styles.col}>
          <BiUser size={20} />
          <span>{task?.assignment.length}</span>
        </div> */}
        <div className={styles.col}>
          <TiArrowForwardOutline size={20} />
          <span>{task?.dueDate && format(new Date(task.dueDate), 'dd/MM/yyyy')}</span>
        </div>
        <div className={styles.status}>
          <div className={`${styles.priority}`}>
            <div className={styles[`level-${task?.priority}`]} />
            <span>
              {task?.priority === 1 && 'Priorité haute'}
              {task?.priority === 2 && 'Priorité moyenne'}
              {task?.priority === 3 && 'Priorité basse'}
            </span>
          </div>
          {task.status
            && (
            <div className={`${styles.state} ${styles[task.status]}`}>
              <span>{tasksStatusOptions.find((s) => s.value === task?.status)?.label}</span>
            </div>
            )}
        </div>

      </div>
    </button>
  );
}
