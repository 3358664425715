import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { BsChevronDown } from 'react-icons/bs';
import { API_URL, TOOL_ID } from '../../actions';
import TaskCard from '../TaskCard/TaskCard';
import TaskForm from '../ProjectTaskForm/ProjectTaskForm';
import Modal from '../ModalComponents/Modal';
import styles from './ProjectPanel.module.scss';
import { EDIT_TASK } from '../../actions/types';
import { putProjectAction } from '../../actions/projectsActions';

function Files({ files }) {
  return (
    <div className={styles.files}>
      {files.map((f) => (
        <a href={`${API_URL}/${f.path}`} target="_blank" rel="noreferrer">
          {f.name}
        </a>
      ))}
    </div>
  );
}

export default function ProjectPanel({ close }) {
  const dispatch = useDispatch();
  const { project, task, isLoading } = useSelector((state) => state.projects);
  const { userAuth } = useSelector((state) => state.auth);
  const isAdmin = userAuth?.tools?.find((p) => p.tool === TOOL_ID)?.role === 'Admin';
  const [tasksIsActive, setTasksIsActive] = useState(true);
  const [isArchived, setIsArchived] = useState(false);
  const [tasks, setTasks] = useState([]);

  const closeTask = () => {
    if (task && !isLoading) {
      dispatch({
        type: EDIT_TASK,
        payload: null,
      });
    }
  };

  useEffect(() => {
    if (!isLoading && project?.tasks) {
      const tasksList = project?.tasks?.map((t) => ({ ...t })).sort((a) => {
        if (a.status === 'todo') {
          return -1;
        }
        if (a.status === 'done') {
          return 1;
        }
        return 0;
      });
      setTasks([...tasksList]);
    }
  }, [project?.tasks, isLoading]);

  return (
    <div className={styles.container}>
      {isAdmin
        ? (
          <a href={`${process.env.REACT_APP_OCTADMIN_URL}/project/${project?._id}`} target="_blank" rel="noreferrer">
            <h4>{project.name}</h4>
          </a>
        )
        : <h4>{project.name}</h4>}
      <button
        type="button"
        className={styles.close}
        onClick={() => close()}
      >
        <IoClose />
      </button>
      <button
        type="button"
        className={styles.toggle}
        onClick={() => setTasksIsActive(!tasksIsActive)}
      >
        <p className={tasksIsActive ? styles.active : ''}>TÂCHES</p>
        <p className={!tasksIsActive ? styles.active : ''}>INFOS</p>
      </button>
      {tasksIsActive
        ? (
          <div className={styles.tasks}>
            <div className={styles.lists}>
              {tasks.filter((t) => t.status !== 'done').map((t) => <TaskCard key={t._id} task={t} />)}
            </div>
            <button
              type="button"
              className={`${styles.archived} ${isArchived ? styles.active : ''}`}
              onClick={() => setIsArchived(!isArchived)}
            >
              <BsChevronDown />
              {' '}
              <p>TÂCHES archivées</p>
            </button>
            <div className={styles.lists}>
              {isArchived && tasks.filter((t) => t.status === 'done').map((t) => <TaskCard key={t._id} task={t} />)}
            </div>
          </div>
        )
        : (
          <div className={styles.project}>
            {project.objective
              && (
              <>
                <label>Objectif(s)</label>
                <p>{project.objective}</p>
              </>
              )}

            {project?.financeFile
              && (
              <>
                <label>Budget</label>
                <Files files={[project?.financeFile]} />
              </>
              )}
          </div>
        )}
      <Modal
        toggle={() => closeTask()}
        open={!!task}
      >
        {task && project?._id && (
          <TaskForm
            task={task}
            tasks={project?.tasks}
            submit={(obj) => putProjectAction(dispatch, project?._id, obj)}
          />
        )}
      </Modal>
    </div>
  );
}
