import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Auth.module.scss';
import { TOOL_ID } from '../../actions';
import { ERROR_AUTH } from '../../actions/types';

export default function SwitchIndex() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userAuth, error } = useSelector((state) => state.auth);

  const clearError = useCallback(() => {
    dispatch({
      type: ERROR_AUTH,
      payload: null,
    });
  }, [dispatch]);

  useEffect(() => () => {
    dispatch({
      type: ERROR_AUTH,
      payload: null,
    });
  }, []);

  useEffect(() => {
    if (userAuth?.tools?.find((p) => p.tool === TOOL_ID)) {
      navigate('/majee/home');
      clearError();
    }
  }, [userAuth]);

  useEffect(() => {
    if (error === 401) {
      dispatch({
        type: ERROR_AUTH,
        payload: null,
      });
      navigate('/login');
    }
  }, [error]);

  return (
    <div className={styles.container} />
  );
}
