/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
// import { format } from 'date-fns';

import { projectsTypesOptions } from '../../constants/constants';

export default function ProjectRow({
  styles,
  project,
  setSearchParams,
  isActive,
}) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      onClick={() => setSearchParams({ project: project._id })}
      className={`${styles.row} ${isActive ? styles.active : ''}`}
    >
      <div className={`${styles.col}`}>
        <p className={`${styles.name}`}>{project?.name}</p>
      </div>
      <div className={`${styles.col}`}>
        <p>{projectsTypesOptions?.find((o) => o.value === project?.type)?.label}</p>
      </div>
      <div className={`${styles.col} ${styles.desktop}`}>
        {/* <p>{project?.endDate && format(new Date(project?.endDate), 'dd/MM/yyyy')}</p> */}
      </div>
    </div>
  );
}
