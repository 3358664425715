import React from 'react';
import {
  WiDaySunny, WiRain, WiDayCloudy, WiDayShowers, WiDayThunderstorm,
} from 'react-icons/wi';
import styles from './Meteo.module.scss';

export default function Meteo({ meteo }) {
  return (
    <div className={styles.meteo}>
      <div className={styles.icon}>
        {meteo?.icon === 'rain' && <WiRain />}
        {meteo?.icon === 'clear' && <WiDaySunny />}
        {meteo?.icon === 'cloudy' && <WiDayCloudy />}
        {meteo?.icon === 'showers' && <WiDayShowers />}
        {meteo?.icon === 'storm' && <WiDayThunderstorm />}
      </div>
      <div className={styles.deg}>
        <p>
          {meteo?.temperature}
          °
        </p>
      </div>
      <div className={styles.city}>
        <h3>{meteo?.city}</h3>
        <p>
          {meteo?.message}
        </p>
      </div>
    </div>
  );
}
