import {
  deleteData, getData, postData, putData,
} from './index';
import {
  ERROR_TODOS, GET_TODOS, POST_TODO, PUT_TODO, DELETE_TODO,
} from './types';

export async function getTodosAction(dispatch) {
  const url = '/todo';
  let todos;
  await getData(ERROR_TODOS, url, dispatch, true).then((response) => {
    todos = response.todos.sort((a, b) => b.status.localeCompare(a.status));
  });
  dispatch({
    type: GET_TODOS,
    payload: todos,
  });
}

export async function postTodoAction(dispatch, obj) {
  const url = '/todo';
  let todo;
  await postData(POST_TODO, ERROR_TODOS, url, dispatch, obj, true).then((response) => {
    todo = response?.todo || response?.company;
  });
  dispatch({
    type: POST_TODO,
    payload: todo,
  });
}

export async function putTodoAction(dispatch, id, obj) {
  const url = `/todo/${id}`;
  let todo;
  await putData(PUT_TODO, ERROR_TODOS, url, dispatch, obj, true).then((response) => {
    todo = response.todo;
  });
  dispatch({
    type: PUT_TODO,
    payload: todo,
  });
}

export async function deleteTodoAction(dispatch, id) {
  const url = `/todo/${id}`;
  let todo;

  await deleteData(ERROR_TODOS, url, dispatch, true).then((response) => {
    todo = response;
  });

  if (todo) {
    dispatch({
      type: DELETE_TODO,
      payload: id,
    });
  }
}
