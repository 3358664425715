import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { TOOL_ID } from '../../actions';
import { tasksStatusOptions } from '../../constants/constants';

import {
  DatePicker, StyledSelect, Textarea,
} from '../../lib/HooksFormFields';
import styles from './TaskForm.module.scss';

export default function TaskForm({
  task,
  submit,
}) {
  const {
    control,
    setValue,
    watch,
    getValues,
    // handleSubmit,
    // formState: {
    //   errors,
    // },
  } = useForm();

  const { userAuth } = useSelector((state) => state.auth);
  const isAdmin = userAuth.tools.find((t) => t.tool === TOOL_ID)?.role === 'Admin';
  const {
    list,
  } = useSelector((state) => state.planning);
  const usersOptions = list?.foundUsers?.map((u) => ({ value: u._id, label: `${u?.profile?.firstName} ${u?.profile?.lastName}` }));
  const dueDateRef = useRef();
  function handleSubmitTask() {
    const values = getValues();
    submit(values);
  }

  useEffect(() => {
    if (task) {
      const propreties = [
        'status',
        'label',
        'assignTo',
      ];
      const dates = [
        'date',
      ];
      propreties.forEach((p) => {
        if (task[p]) {
          setValue(p, task[p]);
        }
      });
      dates.forEach((p) => {
        if (task[p]) {
          setValue(p, new Date(task[p]));
        }
      });
    }
  }, [task]);
  return (
    <div className={styles.container}>
      <h2>{task?._id ? "Modification d'une tâche" : 'Ajout d’une tâche' }</h2>
      <div className={styles.row}>
        <div className={styles['input-state']}>
          <label>Status</label>
          <div className={styles.input}>
            <StyledSelect
              name="status"
              control={control}
              value={tasksStatusOptions.find((o) => o.value === watch('status'))}
              onChange={(opt) => setValue('status', opt.value)}
              placeholder="Séléctionner un status..."
              options={tasksStatusOptions}
            />
          </div>
        </div>
        {watch('status')
          && (
          <div className={`${styles.state} ${styles[watch('status')]}`}>
            <span>{tasksStatusOptions.find((s) => s.value === watch('status'))?.label}</span>
          </div>
          )}
      </div>
      <div className={styles['input-block']}>
        <label>Texte</label>
        <div className={styles.input}>
          <Textarea
            name="label"
            control={control}
            className="primary"
            placeholder=""
          />
        </div>
      </div>
      {isAdmin && usersOptions
      && (
        <div className={styles.section}>
          <h3>Affectation</h3>
          <div className={styles['input-state']}>
            <div className={styles.input}>
              <StyledSelect
                name="assignTo"
                control={control}
                value={usersOptions.filter((opt) => watch('assignTo')?._id === opt.value || watch('assignTo'))}
                onChange={(opt) => setValue('assignTo', opt.value)}
                placeholder="Séléctionner..."
                options={usersOptions || []}
              />
            </div>
          </div>
        </div>
      )}
      <div className={styles.section}>
        <div className={styles['input-block']}>
          <label>Livraison</label>
          <div className={styles.input}>
            <DatePicker
              ref={dueDateRef}
              name="date"
              control={control}
              placeholder="Date de la livraison"
            />
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <button
          type="button"
          onClick={() => handleSubmitTask()}
        >
          Enregistrer la tâche
        </button>
      </div>
    </div>
  );
}
