/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import checkboxChecked from './checkbox-checked.svg';
import checkbox from './checkbox.svg';
import styles from './checkbox.module.scss';

function Checkbox({
  name,
  control,
  rules,
  label,
}) {
  const [selected, setSelected] = useState(false);
  const {
    field: {
      value = '',
      onChange,
    },
  } = useController({
    name,
    control,
    rules,
    defaultValue: '',
  });

  function handleClick() {
    const bool = !value;
    onChange(bool);
    setSelected(bool);
  }

  return (
    <div className={value ? `${styles.checkbox} ${styles.selected}` : `${styles.checkbox}`}>
      {label
        && (
        <label className={selected ? `${styles.selected}` : ''} onClick={handleClick} htmlFor={`checkbox-${name}`}>
          {label}
        </label>
        )}
      {value
        ? <img className="img-checkbox" src={checkboxChecked} alt="checkbox checked" onClick={handleClick} />
        : <img className="img-checkbox" src={checkbox} alt="checkbox" onClick={handleClick} />}
    </div>
  );
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Checkbox;
