import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FiChevronDown } from 'react-icons/fi';

import styles from './Tools.module.scss';
import ToolCard from '../../components/ToolCard/ToolCard';

export default function Tools() {
  const { tools } = useSelector((state) => state.user);
  const { userAuth } = useSelector((state) => state.auth);
  const toolsList = tools?.toolsList;

  const [selected, setSelected] = useState();
  const [categories, setCategories] = useState();
  const [navIsOpen, setNavIsOpen] = useState(false);

  function selectItem(item) {
    setNavIsOpen(false);
    setSelected(item);
  }

  useEffect(() => {
    if (!toolsList && !userAuth?.tools) return;
    const categoriesList = [];

    let list = toolsList;
    let cat = {
      label: 'Tous les outils',
      type: 'all',
      list,
    };
    categoriesList.push(cat);

    list = toolsList?.filter(
      (t) => userAuth?.favoriteTools?.find((f) => f === t._id),
    );
    cat = {
      label: 'Mes favoris',
      type: 'favoris',
      list,
    };
    categoriesList.push(cat);

    list = toolsList?.filter((t) => t.type === 'Outils Octapharma au quotidien');
    cat = {
      label: 'Octapharma',
      type: 'tool',
      list,
    };
    if (list?.length) {
      categoriesList.push(cat);
    }

    list = toolsList?.filter((t) => t.type === 'Outils médicaux interne' || t.type === 'product');
    cat = {
      label: 'Outils',
      type: 'tool',
      list,
    };
    if (list?.length) {
      categoriesList.push(cat);
    }

    list = toolsList?.filter((t) => t.type === 'Outils de veille');
    cat = {
      label: 'Veille',
      type: 'tool',
      list,
    };
    if (list?.length) {
      categoriesList.push(cat);
    }

    list = toolsList?.filter((t) => t.type !== 'Outils Octapharma au quotidien'
        && t.type !== 'Outils médicaux interne'
        && t.type !== 'product'
        && t.type !== 'Outils de veille'
        && t.type !== 'media');
    cat = {
      label: 'Autres',
      type: 'tool',
      list,
    };
    if (list?.length) {
      categoriesList.push(cat);
    }

    list = toolsList?.filter((t) => t.type === 'media');
    cat = {
      label: 'Médias',
      type: 'tool',
      list,
    };
    if (list?.length) {
      categoriesList.push(cat);
    }
    if (selected) {
      setSelected(categoriesList.find((c) => c.label === selected?.label));
    } else {
      setSelected(categoriesList[0]);
    }
    setCategories(categoriesList);
  }, [toolsList, userAuth, userAuth?.favoriteTools]);

  return (
    <div className={styles.container}>
      <div className={`${styles.nav} ${navIsOpen ? styles.isOpen : ''}`}>
        <div className={styles.selected}>
          <h1>{selected?.label}</h1>
          <button
            type="button"
            className={styles.toggle}
            onClick={() => setNavIsOpen(!navIsOpen)}
          >
            <FiChevronDown />
          </button>
        </div>
        <div className={styles.options}>
          {categories?.map((c) => (
            <button
              key={c.value}
              type="button"
              className={`${styles.opt} ${selected.label === c.label ? styles.active : ''}`}
              onClick={() => selectItem(c)}
            >
              {c.label}
            </button>
          ))}
        </div>
      </div>
      <div className={styles.cards}>
        {selected?.list?.map((tool) => <ToolCard key={tool._id} tool={tool} />)}
      </div>
    </div>
  );
}
