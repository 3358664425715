import React, { useEffect, useState } from 'react';
import { BsCalendar4 } from 'react-icons/bs';
import { IoChevronBackSharp, IoChevronForwardSharp } from 'react-icons/io5';
import styles from './SelectMonths.module.scss';

export default function SelectMonths({ month, startDate, selectWeek }) {
  const firstDay = new Date(new Date(startDate).setDate(1));
  const [monthStart, setMonthStart] = useState(firstDay);
  const [weeksOptions, setWeeksOptions] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const monthLong = monthStart && new Intl.DateTimeFormat('fr', { month: 'long', year: 'numeric' }).format(new Date(monthStart));

  const week = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];

  function handleChangeMonth(number) {
    let newMonth = new Date(monthStart);
    if (number > 0) {
      newMonth = new Date(new Date(newMonth).setMonth(newMonth.getMonth() + number));
    }
    if (number < 0) {
      newMonth = new Date(new Date(newMonth).setMonth(newMonth.getMonth() - Math.abs(number)));
    }
    setMonthStart(newMonth);
  }

  function handleChangeWeek(date) {
    selectWeek(date);
    setIsOpen(false);
  }

  useEffect(() => {
    if (monthStart) {
      const d = new Date(monthStart);
      const monthNum = d.getMonth();
      const mondays = [];
      d.setDate(1);
      while (d.getDay() !== 1) {
        d.setDate(d.getDate() + 1);
      }
      while (d.getMonth() === monthNum) {
        mondays.push(new Date(d.getTime()));
        d.setDate(d.getDate() + 7);
      }
      const mondaysOptions = mondays.map((m) => {
        const date = new Date(m);
        const days = [];
        for (let i = 0; i < 7; i++) {
          days.push(new Date(new Date(date).setDate(date.getDate() + i)).getDate());
        }
        return { date, days };
      });
      setWeeksOptions(mondaysOptions);
    }
  }, [monthStart]);

  return (
    <div className={styles.container}>
      <button
        type="button"
        className={styles.toggle}
        onClick={() => setIsOpen(!isOpen)}
      >
        <p>
          {month}
        </p>
        <div className={styles.icon}>
          <BsCalendar4 />
        </div>
      </button>
      <div className={`${styles.select} ${isOpen ? styles.active : ''}`}>
        <div className={styles.nav}>
          <button
            type="button"
            onClick={() => handleChangeMonth(-1)}
          >
            <IoChevronBackSharp />
          </button>
          <p>{monthLong}</p>
          <button
            type="button"
            onClick={() => handleChangeMonth(1)}
          >
            <IoChevronForwardSharp />
          </button>
        </div>
        <div className={styles.options}>
          <div className={styles.week}>
            {week?.map((d, i) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`${d}-${i}`}
                className={styles.day}
              >
                {d}
              </div>
            ))}
          </div>
          {weeksOptions?.map((w) => (
            <button
              key={w.date}
              onClick={() => handleChangeWeek(w.date)}
              type="button"
            >
              {w?.days?.map((d) => (
                <div
                  key={d}
                  className={styles.day}
                >
                  {d}
                </div>
              ))}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
