export function setVhUnit() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  window.addEventListener('resize', () => {
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
}

export function hexToRgba(hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  if (alpha) {
    return `rgba(${ r }, ${ g }, ${ b }, ${ alpha })`;
  }
  return `rgb(${ r }, ${ g }, ${ b })`;
}
