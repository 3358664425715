import { getData, putData } from './index';
import {
  ERROR_PLANNINGS, GET_CR_LIST, GET_PLANNING, GET_PLANNING_LIST, PUT_PLANNING,
} from './types';

export async function getPlanningAction(dispatch, monday, days = 13) {
  const startDate = new Date(monday);
  const endDate = new Date(new Date(startDate).setDate(startDate.getDate() + days));
  const url = `/user/planning?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
  let users;
  await getData(ERROR_PLANNINGS, url, dispatch, true).then((response) => {
    users = response.users;
  });
  dispatch({
    type: GET_PLANNING,
    payload: {
      users,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    },
  });
}

export async function getPlanningListAction(dispatch) {
  const url = '/user/planning/list';
  let lists = {};
  await getData(ERROR_PLANNINGS, url, dispatch, true).then((response) => {
    lists = response;
  });
  await getData(ERROR_PLANNINGS, '/center', dispatch, true).then((response) => {
    lists.centers = response.centers;
  });
  await getData(ERROR_PLANNINGS, '/drug', dispatch, true).then((response) => {
    lists.drugs = response.drugs;
  });
  await getData(ERROR_PLANNINGS, '/therapeuticarea', dispatch, true).then((response) => {
    lists.therapeuticAreas = response.therapeuticAreas;
  });
  await getData(ERROR_PLANNINGS, '/project', dispatch, true).then((response) => {
    lists.projects = response.projects;
  });
  dispatch({
    type: GET_PLANNING_LIST,
    payload: lists,
  });
}

export async function putPlanningAction(dispatch, id, obj) {
  const url = `/user/planning/${id}`;
  let days;
  await putData(PUT_PLANNING, ERROR_PLANNINGS, url, dispatch, obj, true).then((response) => {
    days = response.days;
  });
  dispatch({
    type: PUT_PLANNING,
    payload: {
      user: id,
      days,
    },
  });
}

export async function getPlanningCrAction(dispatch) {
  const url = '/user/planning/cr';
  let days;
  await getData(ERROR_PLANNINGS, url, dispatch, true).then((response) => {
    days = response.days;
  });
  dispatch({
    type: GET_CR_LIST,
    payload: days,
  });
}
