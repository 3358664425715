import React, { useState, useEffect } from 'react';
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import styles from './HtmlEditor.module.scss';
import './toolbar.scss';

function HtmlEditor({ onChange, defaultValue, toolbarEnabled = true }) {
  const [editorState, setEditorState] = useState();
  const [value, setValue] = useState();

  const toolbar = {
    options: ['inline', 'textAlign', 'blockType', 'list'],
    blockType: {
      inDropdown: false,
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    inline: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['bold', 'italic', 'underline'],
    },
  };

  const toolbarHidden = {
    options: [],
  };

  function generateEditorStateFromValue(val) {
    const contentBlock = htmlToDraft(val || '');
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    return EditorState.createWithContent(contentState);
  }

  useEffect(() => {
    if (!value && defaultValue) {
      setValue(defaultValue);
      setEditorState(generateEditorStateFromValue(defaultValue));
    }
    if (!value && !defaultValue) {
      setValue('<p></p>');
      setEditorState(() => EditorState.createEmpty());
    }
  }, [defaultValue]);

  const handleEditorChange = (state) => {
    setEditorState(state);
    const currentContentAsHTML = convertToHTML({
      entityToHTML: (entity, originalText) => originalText,
    })(state.getCurrentContent());
    onChange(currentContentAsHTML);
  };

  return (
    <div>
      {value && editorState
      && (
      <Editor
        defaultEditorState={editorState}
        toolbar={toolbarEnabled ? toolbar : toolbarHidden}
        toolbarClassName={styles.toolbar}
        editorClassName={styles.editor}
        onEditorStateChange={handleEditorChange}
      />
      )}
    </div>
  );
}

export default HtmlEditor;
