/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import styles from './input-text.module.scss';

function InputText({
  name,
  control,
  rules = {},
  widthLabel = 0,
  inline = false,
  label = '',
  placeholder = '',
  className,
}) {
  const {
    field: {
      onChange, onBlur, value, ref,
    },
  } = useController({
    name,
    control,
    rules,
    defaultValue: '',
  });
  return (
    <div className={inline ? `${className ? styles[className] : ''} ${styles['container-input']} ${styles.inline}` : `${className ? styles[className] : ''} ${styles['container-input']}`}>
      { label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
      <input
        ref={ref}
        value={value}
        onBlur={onBlur}
        onChange={(e) => onChange(e.target.value)}
        className={styles.input}
        placeholder={placeholder}
      />
    </div>
  );
}

InputText.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  inline: PropTypes.bool.isRequired,
  widthLabel: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default InputText;
