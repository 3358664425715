import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { BsCheck, BsChevronDown } from 'react-icons/bs';
import { RiAddFill } from 'react-icons/ri';
import { MdDeleteForever } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import {
  deleteTodoAction, getTodosAction, postTodoAction, putTodoAction,
} from '../../actions/todoAction';
import styles from './TasksPanel.module.scss';
import { TOOL_ID } from '../../actions';
import Modal from '../ModalComponents/Modal';
import TaskForm from '../TaskForm/TaskForm';

export function Todos({ displayType = null, isHome = false }) {
  const { userAuth } = useSelector((state) => state.auth);
  const isAdmin = userAuth.tools.find((t) => t.tool === TOOL_ID && t?.role === 'Admin');
  const { todosList } = useSelector((state) => state.todos);
  const [isArchived, setIsArchived] = useState(false);
  const list = todosList?.filter((t) => {
    if (!isAdmin && t.assignTo._id !== userAuth?._id) {
      return null;
    }
    if (isHome && t.assignTo._id !== userAuth?._id) {
      return null;
    }
    return t;
  }).sort((a) => {
    if (a.status === 'todo') {
      return -1;
    }
    if (a.status === 'done') {
      return 1;
    }
    return 0;
  });
  const dispatch = useDispatch();
  useEffect(() => {
    getTodosAction(dispatch);
  }, []);
  return (
    <>
      <div className={styles.list}>
        {list
          ?.filter((t) => t.status !== 'done')
          .map((t) => <TodoItem key={t._id + t.updatedAt} todo={t} />)}
      </div>
      {!displayType && (
        <>
          <button
            type="button"
            className={`${styles.archived} ${isArchived ? styles.active : ''}`}
            onClick={() => setIsArchived(!isArchived)}
          >
            <BsChevronDown />
            {' '}
            <p>TÂCHES archivées</p>
          </button>
          <div className={styles.list}>
            {isArchived && list
              ?.filter((t) => t.status === 'done')
              .map((t) => <TodoItem key={t._id + t.updatedAt} todo={t} />)}
          </div>
        </>
      )}
    </>
  );
}

function TodoItem({ todo }) {
  const dispatch = useDispatch();
  const { userAuth } = useSelector((state) => state.auth);
  const isAdmin = userAuth.tools.find((t) => t.tool === TOOL_ID && t?.role === 'Admin');
  const {
    label, status, assignTo, date,
  } = todo;
  const updatedStatus = status === 'done' ? 'todo' : 'done';
  return (
    <div className={styles.todo}>
      <div className={styles.label}>
        <button
          type="button"
          className={`${styles.check} ${status === 'done' ? styles.done : ''}`}
          onClick={() => putTodoAction(dispatch, todo._id, { status: updatedStatus })}
        >
          <BsCheck />
        </button>
        <p>
          {label}
          <br />
          {isAdmin && assignTo && (
          <span>
            {assignTo?.profile?.firstName}
            {' '}
            {assignTo?.profile?.lastName}
            {' - '}
            {date && format(new Date(date), 'dd/MM/yyyy')}
          </span>
          )}
        </p>
      </div>
      <button
        type="button"
        className={styles.delete}
        onClick={() => deleteTodoAction(dispatch, todo._id)}
      >
        <MdDeleteForever />
      </button>
    </div>
  );
}

export default function TasksPanel({ close }) {
  const dispatch = useDispatch();
  const [task, setTask] = useState();
  return (
    <div className={styles.container}>
      <h3>MES TÂCHES PERSONNELLES</h3>
      <button
        type="button"
        className={styles.close}
        onClick={() => close()}
      >
        <IoClose />
      </button>
      <button
        type="button"
        className={styles.create}
        onClick={() => setTask({})}
      >
        Ajouter une tâche
        <div className={styles.icon}>
          <RiAddFill />
        </div>
      </button>
      <Todos />
      <Modal
        toggle={() => setTask(null)}
        open={!!task}
      >
        {task && (
          <TaskForm
            task={task}
            submit={(obj) => {
              if (!task._id) {
                postTodoAction(dispatch, obj);
              } else putTodoAction(dispatch, task._id, obj);
            }}
          />
        )}
      </Modal>
    </div>
  );
}
