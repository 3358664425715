/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { GrFormView, GrFormViewHide } from 'react-icons/gr';
import styles from './input-password.module.scss';
// At least 8 characters
// On digit
// On uppercase
// On special characters

function InputPassword({
  name,
  control = {},
  required = true,
  widthLabel = null,
  inline = false,
  label = null,
  requiredMessage = '',
  placeholder = '',
  className,
}) {
  const [type, setType] = useState('password');

  function getRequiredMessage() {
    return requiredMessage || 'Le champs mot de password est obligatoire';
  }

  const {
    field: {
      value = '',
      ref,
      onChange,
      onBlur,
    },
  } = useController({
    name,
    control,
    rules: {
      required: required ? getRequiredMessage() : false,
      pattern: {
        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^/\\+\-|=!*?()@%&]).{8,}$/,
        message: 'Le mot de passe doit avoir au moins 8 caractères, en chiffres, en majuscules et un caractère spécial',
      },
    },
    defaultValue: '',
  });

  return (
    <div className={inline ? `${className ? styles[className] : ''}  ${styles['container-input-password']} ${styles.inline}` : `${className ? styles[className] : ''}  ${styles['container-input-password']}`}>
      { label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
      <div className={styles['container-input']}>
        <input
          ref={ref}
          name={name}
          value={value}
          onBlur={onBlur}
          onChange={(e) => onChange(e.target.value)}
          className={styles.input}
          placeholder={placeholder}
          type={type}
          autoComplete="on"
        />
        <button
          type="button"
          className={styles['container-icon']}
          onClick={() => setType((t) => (t === 'password' ? 'text' : 'password'))}
        >
          { type === 'password'
            ? <GrFormView size={30} /> : <GrFormViewHide size={30} />}
        </button>
      </div>
    </div>

  );
}

InputPassword.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default InputPassword;
