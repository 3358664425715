/* eslint-disable react/no-danger */
import { format } from 'date-fns';
import React from 'react';
import { FiCalendar } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { API_URL } from '../../actions';
import { SET_ACTIVE_ACTU } from '../../actions/types';

import styles from './ActuCard.module.scss';

export default function ActuCard({ card, type }) {
  const dispatch = useDispatch();
  function setActiveActu() {
    dispatch({
      type: SET_ACTIVE_ACTU,
      payload: { ...card, type },
    });
  }
  return (
    <button
      type="button"
      className={styles.container}
      onClick={() => setActiveActu()}
    >
      <div className={styles.content}>
        {card?.image?.path
        && (
        <div className={styles.image}>
          <img src={`${API_URL}/${card.image.path}`} alt={card?.image?.alt} />
        </div>
        )}
        <div className={styles.infos}>
          <h4>{card?.title || card?.name}</h4>
          {card.description && <p className={styles.text}>{card.description}</p>}
          {card.texte && (
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: card.texte }}
          />
          )}
        </div>
      </div>
      {type === 'congres'
        && (
        <div className={styles.dates}>
          <div className={styles.date}>
            <FiCalendar color="#4F3CC8" />
            {card?.startDate && (
            <p>
              Début :
              {' '}
              {format(new Date(card?.startDate), 'dd/MM/yyyy')}
            </p>
            )}
          </div>
          <div className={styles.date}>
            <FiCalendar color="#4F3CC8" />
            {card?.endDate && (
            <p>
              Fin :
              {' '}
              {format(new Date(card?.endDate), 'dd/MM/yyyy')}
            </p>
            )}
          </div>
        </div>
        )}
      {type !== 'congres'
        && (
        <div className={`${styles.dates} ${styles[type]}`}>
          <div className={styles.date}>
            <FiCalendar />
            {card?.date && (
            <p>
              {format(new Date(card?.date), 'dd/MM/yyyy')}
            </p>
            )}
          </div>
          <div>
            <p className={styles.author}>
              {card?.author?.profile?.firstName}
              {' '}
              {card?.author?.profile?.lastName}
            </p>
          </div>
        </div>
        )}
    </button>
  );
}
