import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getNotificationsAction } from '../../actions/notificationsActions';
import Notifictions from '../../components/Notifications/Notifications';
import { hexToRgba } from '../../utils/utils';
import noNotifs from '../../assets/images/no_notifs.svg';
import styles from './Home.module.scss';
import Meteo from '../../components/Meteo/Meteo';
import { Todos } from '../../components/TasksPanel/TasksPanel';
import { getProjectsAction } from '../../actions/projectsActions';
import TaskCard from '../../components/TaskCard/TaskCard';
import { getPlanningAction, getPlanningListAction } from '../../actions/planningActions';
import HomePlanning from '../../components/HomePlanning/HomePlanning';

export default function Home() {
  const dispatch = useDispatch();
  const { userAuth } = useSelector((state) => state.auth);
  const { gif, meteo, tools } = useSelector((state) => state.user);
  const { notifications } = useSelector((state) => state.notifs);
  const { projectsList } = useSelector((state) => state.projects);
  const { todosList } = useSelector((state) => state.todos);

  const { firstName } = userAuth;
  const toolsList = tools?.toolsList ? [...tools.toolsList] : [];
  const newNotifs = notifications?.filter((n) => n.new);
  const todayFormated = new Intl.DateTimeFormat('fr', { dateStyle: 'full' }).format(new Date());

  const today = new Date();

  const getData = useCallback(() => {
    getNotificationsAction(dispatch);
    getProjectsAction(dispatch);
    getPlanningAction(dispatch, today, 2);
    getPlanningListAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={styles.container}>
      <main>
        <div className={styles.header}>
          <div className={styles.name}>
            <h2>
              Hello
              {' '}
              {firstName}
              {' '}
              !
            </h2>
            <p>{todayFormated}</p>
          </div>
          {meteo && <Meteo meteo={meteo} /> }
        </div>
        <div className={styles.tools}>
          {toolsList
            ?.filter((t) => t._id !== '6374e80d96463a6463ea7bd2')
            ?.filter((tool) => userAuth?.favoriteTools?.find((t) => t === tool._id))
            ?.sort((a, b) => a.title.localeCompare(b.title))
            ?.map((t) => (
              <a
                key={t._id}
                href={t.url}
                target="_blank"
                rel="noreferrer"
              >
                <div
                  className={styles.icon}
                  style={{ backgroundColor: hexToRgba(t.color, 0.2) }}
                >
                  <span
                    style={{ color: t.color }}
                  >
                    {t.title[0]}
                  </span>
                </div>
                <p>{t.title}</p>
              </a>
            ))}
        </div>
        <div className={styles.row}>
          <div className={`${styles.col}`}>
            <h2>Au programme</h2>
            <HomePlanning />
          </div>
          <div className={`${styles.col} ${styles.notifs}`}>
            <h2>
              Notifications non lues
              {' '}
              {newNotifs?.length > 0 && `(${newNotifs?.length})`}
            </h2>
            <Link to="/majee/notifications" className={styles.link}>
              Voir toutes
            </Link>
            {newNotifs?.length
              ? <Notifictions notifications={newNotifs} />
              : (
                <div className={styles['no-notifs']}>
                  <p>Vous n’avez pas de nouvelles notifications</p>
                  <div className={styles.illu}>
                    <img src={noNotifs} alt="no-notif" />
                  </div>
                </div>
              )}
          </div>
        </div>
      </main>
      <aside>
        <div className={styles.gif}>
          <h3>Le gif du jour</h3>
          <div className={styles.image}>
            {gif && <img src={gif} alt="" />}

          </div>
          {todosList.filter((t) => t.status === 'todo').length > 0
            && (
            <>
              <h3>Tâches à venir</h3>
              <Todos displayType="todo" isHome />
            </>
            )}
          <div className={styles['tasks-container']}>
            <h3>Tâches projets</h3>
            {projectsList?.map((p) => (
              <div key={p._id} className={styles.project}>
                {p?.tasks?.filter(
                  (t) => t?.assignment?.find((a) => a === userAuth?._id) && t.status === 'todo',
                ).map((t) => <TaskCard key={t._id} task={t} projectId={p._id} />)}
              </div>
            ))}
          </div>
        </div>
      </aside>
    </div>
  );
}
