import React, { useEffect, useState } from 'react';
import uniqid from 'uniqid';
import { isSameDay } from 'date-fns';
import { IoChevronBackSharp, IoChevronForwardSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getPlanningAction, getPlanningListAction } from '../../actions/planningActions';

import styles from './Planning.module.scss';
import PlanningRow from '../../components/PlanningRow/PlanningRow';
import useWindowSize from '../../hooks/useWindowSize';
import Checkbox from '../../lib/HooksFormFields/Checkbox';
import CrPanel from '../../components/CrPanel/CrPanel';
import SelectMonths from '../../components/SelectMonths/SelectMonths';
import { SET_ACTIVE_CR_LIST } from '../../actions/types';
import CrList from '../../components/CrList/CrList';

export default function Planning() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { width } = useWindowSize();
  const [daysCount, setDaysCount] = useState(6);
  const { userAuth } = useSelector((state) => state.auth);
  const {
    planning, list, startDate, endDate, isCrList,
  } = useSelector((state) => state.planning);
  const {
    watch,
    control,
  } = useForm({
    defaultValues: {
      weekend: true,
    },
  });
  const isWeekend = watch('weekend');

  const weekStart = searchParams.get('startDate');
  const today = new Date();
  const currentWeekDay = today.getDay();
  const lessDays = currentWeekDay === 0 ? 6 : currentWeekDay - 1;
  const lastMonday = new Date(new Date(today).setDate(today.getDate() - lessDays));
  const month = startDate && new Intl.DateTimeFormat('fr', { month: 'long' }).format(new Date(startDate));

  const [planningData, setPlanningData] = useState([]);

  const getData = () => {
    let stDate = new Date(lastMonday);
    if (weekStart) {
      stDate = new Date(weekStart);
    } else {
      setSearchParams({ startDate: stDate });
    }
    getPlanningAction(dispatch, stDate, daysCount);
    getPlanningListAction(dispatch);
  };

  useEffect(() => {
    if (width) {
      if (width < 1024) {
        setDaysCount(6);
      }
      getData();
    }
  }, [width]);

  useEffect(() => {
    if (planning.length && startDate && list?.types) {
      const users = [];
      let date = new Date(startDate);
      const dates = [date];
      while (date < new Date(endDate)) {
        date = new Date(new Date(date).setDate(date.getDate() + 1));
        dates.push(date);
      }
      planning.forEach((p) => {
        const days = [];
        dates.forEach((d) => {
          const day = {
            date: d,
            key: uniqid(),
            day: [
              {
                type: null,
                period: 'all',
                key: uniqid(),
                user: p.user._id,
              },
            ],
          };
          day.day = day?.day?.map((da) => {
            const data = { ...da };
            if (d.getDay() === 0 || d.getDay() > 5) {
              data.type = 'weekend';
            }
            return data;
          });
          const found = p.days.filter((da) => isSameDay(new Date(da.date), new Date(d)));
          if (found?.length > 0) {
            day.day = found.map((da) => ({
              type: da.type, period: da.period, cr: da.cr, key: uniqid(),
            }))
              .sort((a, b) => a.period.localeCompare(b.period));
          }
          days.push(day);
        });
        const user = {
          user: p.user,
          days,
        };
        if (userAuth?._id === p.user._id) {
          users.unshift(user);
        } else {
          users.push(user);
        }
      });
      setPlanningData(users);
    }
  }, [planning, list]);

  function setPlanningDate(number) {
    let firstDate = new Date(lastMonday);
    if (weekStart) {
      firstDate = new Date(weekStart);
    }
    if (number > 0) {
      firstDate = new Date(new Date(firstDate).setDate(firstDate.getDate() + number));
    }
    if (number < 0) {
      firstDate = new Date(new Date(firstDate).setDate(firstDate.getDate() - Math.abs(number)));
    }
    setSearchParams({ startDate: firstDate });
    getPlanningAction(dispatch, firstDate, daysCount);
  }

  function selectWeek(monday) {
    setSearchParams({ startDate: monday });
    getPlanningAction(dispatch, monday, daysCount);
  }

  function selectView(days) {
    setDaysCount(days);
    getPlanningAction(dispatch, new Date(lastMonday), days);
  }

  return (
    <div className={styles.container}>
      <h2>PLANNING</h2>
      {startDate
        && (
        <div className={styles.header}>
          {month && (
            <SelectMonths
              month={`${month} ${new Date(startDate).getFullYear()}`}
              startDate={startDate}
              daysCount={daysCount}
              selectWeek={(date) => selectWeek(new Date(date))}
            />
          )}
          <div className={styles.actions}>
            <div className={styles.view}>
              <div className={styles.check}>
                <Checkbox name="weekend" control={control} label="Afficher les weekends" />
              </div>
              <button
                type="button"
                className={daysCount === 6 ? styles.active : ''}
                onClick={() => selectView(6)}
              >
                vue Semaine
              </button>
              <button
                type="button"
                className={daysCount === 27 ? styles.active : ''}
                onClick={() => selectView(27)}
              >
                vue Mois
              </button>
              <button
                type="button"
                className={`${styles.cr} ${isCrList ? styles.active : ''}`}
                onClick={() => dispatch({
                  type: SET_ACTIVE_CR_LIST,
                  payload: true,
                })}
              >
                compte-rendus
              </button>
            </div>
            <div className={styles.nav}>
              <button
                type="button"
                onClick={() => setPlanningDate(-7)}
              >
                <IoChevronBackSharp />
              </button>
              <button
                type="button"
                onClick={() => setPlanningDate(7)}
              >
                <IoChevronForwardSharp />
              </button>
            </div>
          </div>
        </div>
        )}
      <div className={styles.planning}>
        <div className={styles.content}>
          {planningData?.map((p) => (
            <PlanningRow
              key={p.user._id}
              user={p.user}
              days={p.days}
              isMobile={width < 1024}
              isMonth={daysCount > 7}
              isWeekend={isWeekend}
              isAuth={userAuth?._id === p.user._id}
              isAdmin={userAuth.role === 'Admin'}
            />
          ))}
        </div>
      </div>
      <CrList />
      <CrPanel />
    </div>
  );
}
