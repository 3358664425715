import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../lib/HooksFormFields';
import styles from './Page404.module.scss';

export default function Page404() {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <p>Cette page est introuvable</p>
      <Button
        handleClick={() => navigate(-1)}
      >
        RETOUR
      </Button>
    </div>
  );
}
