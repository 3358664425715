export const projectsTypesOptions = [
  { value: 'medical', label: 'Médical' },
  { value: 'marketing', label: 'Marketing' },
];

export const tasksStatusOptions = [
  {
    label: 'A faire',
    value: 'todo',
  },
  {
    label: 'En cours',
    value: 'doing',
  },
  {
    label: 'Fait',
    value: 'done',
  },
];
